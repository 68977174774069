@import "../../../Styles/_variables";
@import "../../../Styles/_mixins";

.add-to-watchlist-modal-content {
  .add-to-watchlist-button-wrapper {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.5rem;

    i {
      scale: 1.3;
      position: relative;
      top: 1px;
    }
  }

  .add-to-watchlist-list {
    background: $marine-blue;
    min-height: 30vh;
  }

  .watchlist-item {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    border-bottom: 1px $royal-blue solid;
    padding-left: 1rem;
  }

  .no-watchlists-for-sensor {
    display: flex;
    text-align: center;
    padding-top: 5rem;
  }
}

.watchlist-modal {
  min-width: 480px;
}
