.select-group {
  margin-left: 0;
  padding: 0;
  list-style: none;
  box-shadow: 0 1px 0 0 $sky-blue;

  overflow-x: hidden;
  border-bottom: 1px solid $royal-blue;
  position: relative;

  &.fix-height {
    max-height: em(200);
    overflow-y: auto;

    &::-webkit-scrollbar {
      background-color: $marine-blue;
      position: absolute;
      width: 10px;
      margin-right: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(128, 194, 255, 0.4);
      border-radius: 6px;
      width: 8px !important;

      @include link-active {
        background-color: rgba(128, 194, 255, 0.5);
      }
    ;
    }

    &::-webkit-scrollbar-track-piece {
      background: transparent;
    }
  }

  &-item {
    cursor: pointer;
    background: $marine-blue;
    border-bottom: 1px solid $royal-blue;
    position: relative;
    min-height: em(40);
    padding: $padding-base-vertical $padding-base-horizontal;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    &.selected {
      background: $light-teal;
      color: $blk-blue;
      stroke: $blk-blue;
    }

    * {
      margin-bottom: 0;
    }

    &.header {
      background: transparent;
      height: auto;
      text-transform: uppercase;
      color: $baby-blue;
      font-size: em(12);

      @include link-active {
        background: transparent;
      }
    ;

      &:after {
        display: none !important;
      }
    }

    &.large {
      height: auto;
    }

    &:not(.selected) {
      @include link-active {
        background-color: rgba($card-blue, 0.7);
      }
    }
  ;

    .first-col {
      padding-left: 40px;
    }

    &.isSaving {
      pointer-events: none;

      &:before {
        @extend .fa;
        @extend .fa-spinner;
        @extend .fa-spin;
        color: $teal;
        position: absolute;
        left: 10px;
        transform: translate(0, -50%);
      }
    }
  }
}
