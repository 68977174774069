.badge {
   position: absolute;
   top: 0;
   right: 0;
   transform: translate(-30%, -50%);
   text-align: center;

   @include mobile-only {
    transform: translate(-10%, -50%);
   }
}

.btn-badge {
  background: $warning;
  color: $blk-blue;
  font-size: 12px;
  font-weight: bold;
  width: 16px;
  height: 16px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
  padding: 0 4.5px;
  border-radius: 50%;
  top: 5px;
  display: block;

  &.btn-badge-alert {
    background: $alert;
  }
}

.btn-badge-sm {
  width: 10px;
  height: 10px;
  padding: 0;
}

.btn-badge-lg {
  width: 17px;
  height: 17px;
  font-size: 10px;
  letter-spacing: 0;
  padding: 2px 4px;
}
