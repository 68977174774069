@use "sass:math";

@mixin vertical-center($position: absolute) {
  position: $position;
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

@function em($num, $base: 14) {
  @return math.div($num, $base) * 1em;
}

@mixin desktop-only() {
  @media only screen and (min-width: 992px) {
    @content;
  }
}

@mixin mobile-only() {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin tablets-only() {
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin small-mobile-only {
  @media only screen and (max-width: 375px) {
    @content;
  }
}

@mixin tablet-and-mobile() {
  @media only screen and (max-width: 991px) {
    @content;
  }
}

@mixin small-desktop() {
  @media only screen and (max-width: em(1024)) {
    @content;
  }
}

@mixin mobile-small {
  @media only screen and (max-width: em(500)) {
    @content;
  }
}

@mixin link-active {
  &:hover,
  &.hover,
  &.active {
    @content;
  }
}

@mixin sprites {
  display: inline-block;
  background-image: url(/img/sprites.png);
}

@mixin no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
