.alert-config-wrap {
  display: flex;
  flex-direction: column;
}

.alert-config-title {
  padding: 30px 15px;
}

.alert-config-wrap-row {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.alert-config-wrap-flex-group {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  .input-range {
    width: calc(100% - 7.5rem);
  }

  .input-holder .input {
    padding-right: 0.5rem;
  }
}
