.jumbotron {
  width: 577px;
  min-height: em(406);
  background-image: radial-gradient(circle at 50% 0, #1565b0, #02386b);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3);
  border: solid 1px #002038;
  margin: 10vh auto 0 auto;
  padding: 20px 15px 30px;

  @include tablets-only {
    width: 380px;
  }

  @include mobile-only {
    flex: 1;
    margin: 0;
    width: 100%;
  }
}

.jumbotron-header {
  padding: 0 0 45px 0;
}
