@import "../Styles/variables";
@import "../Styles/mixins";

.location-modal-content-wrapper {
  .left-rail {
    height: 100%;
    max-height: calc(750px - 41px);
  }

  .left-rail-body {
    padding: 6px 4px;

    .left-rail-nav-header {
      padding: unset !important;
    }

    .left-rail-nav-item {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 5px 0 5px 12px !important;
      border-bottom: 1px solid black;

      .left-rail-favorite-item {
        display: grid;
        max-width: 215px;
        width: 215px;
      }

      i {
        cursor: pointer;
        color: $btn-primary-bg;
        scale: 1.2;
      }
    }
  }
}

.manage-locations-header {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  align-items: center;
  gap: 1.25rem;

  &.manage-list {
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
  }

  .search-bar {
    display: flex;
    align-items: center;
  }

  .header-icon {
    cursor: pointer;
  }

  button {
    height: 34px;
  }
}

.manage-locations-list-wrapper {
  min-height: 530px;
}

.manage-locations-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem 2.25rem;

  &.favorite-list {
    grid-template-columns: 1fr 1fr;
  }

  .location-card {
    max-height: 156px;
    margin-bottom: 1rem;
    border: 1px solid transparent;

    &.selected {
      border: 1px solid white;
      box-sizing: content-box;
    }

    .location-card-header {
      height: 36px;
      background: #094a86;
      font-family: $primary-font-family;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.06em;
      text-align: left;
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1.25rem;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

      i {
        cursor: pointer;
        color: $btn-primary-bg;
        scale: 1.2;
      }

      svg {
        cursor: pointer;
        color: $btn-primary-bg;
        width: 16.5px;
        height: 16.5px;
        scale: 1.2;
      }
    }

    .location-card-body {
      background: $marine-blue;
      font-family: $primary-font-family;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.06em;
      text-align: left;
      padding: 0.5rem 1.25rem;
      max-height: 120px;
      height: 120px;
      overflow: auto;
      color: #ffffffb2;
      cursor: pointer;
    }
  }
}

.pagination-text-showing {
  padding: 0 8px;
}

.paginator-locations {
  display: flex;
}

.locations-modal-body {
  padding: 15px 15px 0 15px !important;
}

.locations-footer {
  display: none !important;
}

.manage-locations-title-section {
  display: flex;
  padding: 2px 2px 0 2px;
  justify-content: space-between;
  width: 100%;
  vertical-align: center;
  color: $teal;

  .left-rail-nav-header-title {
    align-self: center;
  }

  .btn-location {
    margin-right: 5px;
  }

  .add-button i {
    position: relative;
    top: 1px;
  }
}

.right-panel {
  position: relative;
}

div.modal-body.locations-modal-body .left-rail-nav-item {
  padding: 10px 0 10px 1.5em;
}

div.modal-body.locations-modal-body .left-rail-nav-item > a {
  color: $white;
  cursor: pointer;
  display: block !important;
}

div.modal-body.locations-modal-body button.delete-button {
  position: absolute;
}

.manage-locations-title-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  vertical-align: center;
  color: $teal;

  .left-rail-nav-header-title {
    align-self: center;
  }

  .btn-location {
    margin-right: 5px;
  }

  .add-button i {
    position: relative;
    top: 1px;
  }
}

input.input-with-icon {
  font-family: $primary-font-family, FontAwesome;
}

@include mobile-only {
  .modal-body.locations-modal-body {
    padding: 15px;

    .locations-select-mobile {
      background-color: black;
      padding: 6px 12px;

      div.MuiSelect-select {
        padding-top: 4px;
        padding-bottom: 4px;
      }

      svg.MuiSvgIcon-root {
        color: #04cca0;
        transform: scale(1.5, 1.5);
      }
    }

    #or-text {
      text-align: center;
      font-size: 16px;
      margin-bottom: -10px;
    }

    #locations-modal-right-panel {
      margin-left: 0;

      #city-state-zipcode-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > div {
          flex-basis: 50%;
        }

        > #country-select-mobile {
          width: 0;
        }
      }

      #country-phone-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > div {
          flex-basis: 100%;
        }
      }

      #allocated-users-container {
        > input:first-of-type {
          margin-bottom: 10px;
        }

        > ul {
          overflow: hidden;
          max-height: unset;
        }

        div.pagination-wrapper {
          justify-content: center;

          > button {
            min-width: unset;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            //align-self: center;
          }

          > button:first-of-type > span:first-of-type {
            display: none;
          }

          > button:last-of-type > span:first-of-type {
            display: none;
          }

          > nav {
            align-content: center;
          }
        }
      }
    }
  }

  .modal-footer .locations-modal-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;

    > button.btn {
      flex-basis: 45%;
      margin-left: 0;
    }

    .delete-button-mobile {
      align-content: center;
      align-items: center;
      justify-content: center;
      font-size: 14px;

      > img {
        width: 13px;
        height: 14px;
        margin-right: 7px;
      }
    }
  }
}

.delete-location-button {
  margin-right: auto;
  text-transform: uppercase;
}
