@import '../Styles/variables';
@import '../Styles/mixins';

.input-range {
  width: 100%;
  display: flex;
  flex-direction: row;

  &.min {

    .input-range-button.max,
    .input-range-field.max {
      display: none;
    }
  }

  &.max {

    .input-range-button.min,
    .input-range-field.min {
      display: none;
    }
  }

  &.readonly {
    .input-range-field.min {
      text-align: right;
    }

    .input-range-button.readonly {
      background-color: $warning;
      background-image: none;
      border-color: $warning;
    }
  }
}

.input-range-field {
  width: 85px;
  display: inline-block;
  margin: 8px;

  .input {
    padding: 6px 0px 6px 8px !important;
  }

  .input-label {
    padding: 2px 0 0 2px;
  }
}

.input-range-slider {
  width: calc(100% - 135px);
  height: 21px;
  margin: 7px;
  position: relative;
  overflow: hidden;
  display: inline-block;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 7px;
    background: rgba(255, 255, 255, 0.4);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0, -50%);
  }
}

.input-range-button {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  height: 21px;
  width: 11px;
  border: solid 1px #00192b;
  background-color: #0068b3;
  background-image: linear-gradient(to bottom, #0068b3, #005694);

  &:after {
    display: block;
    width: 999px;
    height: 7px;
    top: 6px;
    background-color: $warning;
    content: ' ';
    position: absolute;
    border-radius: 0;
  }

  &.min {
    left: 0;

    &:after {
      right: calc(100% + 1px);
    }
  }

  &.max {
    right: 0;

    &:after {
      left: calc(100% + 2px);
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

.input-range-mobile-tablet {
    display: grid;
    grid-template-columns: 1fr;


    .input-range-min-max-mobile-tablet {
        display: flex;
        gap: 10px;
        margin: 5px 0 10px 0;
    }

    .min-max-wrapper {
        display: inline-flex;
        justify-content: space-between;
    }

    .slider {
        width: 95%;
        margin: 0 auto;
    }
}