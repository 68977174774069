@import '../../Styles/mixins';

ul.confirmation-step-list {
  padding: 0 0 0 16px;

  .MuiListItem-root {
    display: list-item;
    padding-right: 0;
    @include mobile-only {
      padding: 2px 0 2px 4px;
    }
  }
}

.confirmation-step-list-item-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

div.confirmation-step-second-row-double-width {
  display: grid;
  grid-template-columns: 1fr 2fr;
  @include mobile-only {
    grid-template-columns: 1fr;
    margin-bottom: 30px;
  }
}

.confirm-step-container {
  margin-bottom: 20px;
}