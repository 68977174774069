@import "../Styles/mixins";
@import "../Styles/variables";

.line-chart-holder {
  padding: 8px 8px 0 0;
  position: relative;
  width: 100% !important;

  .chart-load-indicator {
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    width: 100%;
  }
}

#root .bb {
  display: flex;
}

path.bb-shape {
  shape-rendering: optimizeSpeed;
}

@include tablet-and-mobile {
  .show-tooltip {
    .bb-tooltip-container {
      display: unset !important;
      visibility: unset !important;
    }
  }
}

.bb-button .bb-zoom-reset {
  color: $btn-default-color;
}

.bb-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  opacity: 0.9;
  box-shadow: 7px 7px 12px -9px #777777;
  background-color: $marine-blue;
  color: white;

  th {
    background-color: $marine-blue;
    color: $white;
  }

  td {
    background-color: $marine-blue;
    color: white;
  }
}

.bb-lines-values path {
  stroke-width: 3px;
}

.bb-axis line,
.bb-axis .tick,
.bb-axis .domain {
  stroke-width: 0.8;
  stroke: $light-teal;
}

.bb-axis line {
  stroke: $light-teal;
}

.bb-grid line {
  stroke: #00d1a42f;
  stroke-dasharray: none;
  stroke-width: 1px;
}

.watchlist-graph {
  .set_points,
  .values,
  .values2,
  .values3,
  .values4 {
    stroke-width: 2px;
  }
}

.selection {
  fill: $light-teal;
  fill-opacity: 0.2;
  stroke: none;
}

.chart-gradient-background {
  background: hsla(208, 89%, 25%, 1);

  background: linear-gradient(90deg, hsla(208, 89%, 25%, 1) 0%, hsla(208, 85%, 28%, 1) 50%, hsla(208, 89%, 25%, 1) 100%);

  background: -moz-linear-gradient(90deg, hsla(208, 89%, 25%, 1) 0%, hsla(208, 85%, 28%, 1) 50%, hsla(208, 89%, 25%, 1) 100%);

  background: -webkit-linear-gradient(90deg, hsla(208, 89%, 25%, 1) 0%, hsla(208, 85%, 28%, 1) 50%, hsla(208, 89%, 25%, 1) 100%);
}
