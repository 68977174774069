@import '../../Styles/variables';
@import '../../Styles/mixins';

.email-verification-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-top: 40px;
  text-align: center;
}

.code-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.resend-code-button {
  color: $teal;
  margin-left: 8px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 0.5;
  }
}

.email-verification-error {
  bottom: 0;
  color: $alert;
  font-size: $small-size;
  position: absolute;
  white-space: nowrap;
}

.email-verified-message {
  font-size: $h1-size;

  @include mobile-only {
    font-size: $h3-size;
  }
}
