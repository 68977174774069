@import "../../../../Styles/variables";
@import "../../../../Styles/mixins";

.note-cards {
  .note-card {
    background-color: $marine-blue;
  }

  .btn-icon {
    border-radius: 20%;
    background-color: $btn-default-bg;
    border: 2px solid $btn-default-border;
    color: $blk-blue;
  }
}
