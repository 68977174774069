@import '../../Styles/mixins';

ul.confirmation-step-list {
  padding: 0 0 0 16px;

  .MuiListItem-root {
    display: list-item;
    padding-right: 0;
  }
}

.confirmation-step-list-item-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

div.confirmation-step-second-row-double-width {
  display: grid;
  grid-template-columns: 1fr 2fr;
  @include mobile-only {
    grid-template-columns: 1fr;
  }
}
