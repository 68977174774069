@import "../../Styles/variables";
@import "../../Styles/mixins";

.mobile-tablet-icon {
  @include tablet-and-mobile {
    scale: 1.1;
  }
}

.delete-sensor-icon {
  scale: 1.25;
  color: $teal;
}

.watchlist-graph-loader-wrapper {
  min-height: 30%;
  position: relative;

  .watchlist-loader {
    font-size: 2.5em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.watchlist-sensors-table-empty {
  text-align: center;
  padding: 2rem;
}

.watchlist-no-graph-data {
  min-height: 248px;
  text-align: center;
  margin-top: 80px;
}

.watchlists-show-desktop-checkbox {
  position: relative;
  bottom: 3px;
  left: 2px;

  .input-checkbox:checked + .input-label-checkbox:not(.isSaving):before {
    color: $teal !important;
  }
}

.watchlists-mobile-first-section {
  display: flex;
  width: 90%;
  .watchlist-name {
    padding: 2rem;
  }
}

.watchlist-table-row {
  div {
    height: 30px;
    align-items: center;
    display: flex;
  }
}

.watchlist-legend-wrapper {
  .legend-wrapper {
    max-width: 80px;
    min-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 30px;
    right: 15px;

    .watchlist-line {
      position: absolute;
      top: 45%;
      left: 0;
      right: 0;
      height: 3px;
      z-index: 1;
      margin: 0 0.5rem;
      max-width: 30px;
      transform: translateX(62%);
    }

    .watchlist-shape {
      position: relative;
      z-index: 1;
      width: 15px;
      height: 15px;
      display: none;
    }
  }

  /* Circle */
  .watchlist-shape.circle {
    border-radius: 50%;
  }

  /* Square (default, no extra class needed) */
  .watchlist-shape.square {
    /* No special styles required as it's a default square */
  }

  /* Diamond (rotated square) */
  .watchlist-shape.diamond {
    transform: rotate(45deg);
  }

  /* Triangle */
  .watchlist-shape.triangle {
    width: 0;
    height: 0;
    border-left: 10px solid #002e51;
    border-right: 10px solid #002e51;
    border-bottom: 20px solid #00d1a4;
    background-color: transparent;
    z-index: 0;
  }
}

.watchlistSensors-mobile-tablet {
  margin-bottom: 20px;

  .input-label {
    padding: 0 0 0.5rem 0;
    text-align: start;
  }

  .input-checkbox:checked + .input-label-checkbox:not(.isSaving):before {
    color: $teal !important;
  }

  .sensors-column {
    display: flex;
    flex-direction: column;
  }

  .watchlistSensor-row {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    gap: 1rem;
    padding: 0.25rem;
  }

  .watchlistSensor-row .row-1 {
    display: flex;
    gap: 1rem;
  }

  .watchlistSensor-row .row-1 .field {
    display: flex;
    flex: 1;
  }

  .watchlistSensor-row .row-1 .field .input-label {
    font-weight: bold;
    margin-right: 0.5rem;
  }

  .watchlistSensor-row .row-2 {
    display: flex;
  }

  .watchlistSensor-row .value {
    margin-left: auto;
    margin-right: 6rem;
  }

  .watchlistSensor-row .row-2 .input-label {
    font-weight: bold;
  }

  .watchlistSensor-row .row-3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  .delete-sensor-icon {
    scale: 1.25;
    color: $teal;
    margin-left: auto;
    margin-right: 8rem;
  }
}
