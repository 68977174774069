// - Mixins
@mixin btn-special($bg-color, $color, $border, $shadow: true) {
  background: $bg-color;
  color: $color;
  border: 1px solid $border;

  @if ($shadow) {
    box-shadow: $btn-shadow;
  }

  @include link-active {
    background-color: lighten($bg-color, 7%);

    @if ($shadow) {
      box-shadow: $btn-shadow-hover;
    }
  }

  &:active {
    background-color: darken($bg-color, 3%);
  }

  &.disabled,
  &:disabled {
    color: rgba($color, 0.3);
    cursor: not-allowed;
    pointer-events: none;
  }
}

.btn {
  color: $blk-blue;
  display: inline-block;
  margin-bottom: 0;
  font-weight: $bold-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: $padding-base-vertical 8px;
  font-size: $p-size;
  line-height: $line-height-base;
  border-radius: $btn-border-radius-base;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  letter-spacing: 1px;
  position: relative;

  &:focus {
    outline: none;
  }

  &.readonly {
    cursor: text;
    padding: 4px 6px;
    background-color: transparent;
    color: $white;
    border-color: $white;
    box-shadow: none;

    @include link-active {
      background-color: transparent !important;
      box-shadow: none !important;
    }

  ;
  }
}

.btn-sm {
  font-size: $small-size;
}

.btn-default {
  @include btn-special($btn-default-bg, $btn-default-color, $btn-default-border);
}

.btn-primary {
  @include btn-special($btn-primary-bg, $btn-primary-color, $btn-primary-border);

  &.disabled,
  &:disabled {
    background-color: #03b38d;
  }

  &.readonly {
    @include link-active {
      background-color: $btn-primary-bg;
    }
  }
}

.btn-warning {
  @include btn-special($btn-warning-bg, $btn-default-color, $btn-warning-border);

  &.disabled,
  &:disabled {
    background-color: $btn-warning-bg;
  }

  &.readonly {
    @include link-active {
      background-color: $btn-warning-bg;
    }
  }
}

.btn-danger {
  @include btn-special($btn-danger-bg, $btn-default-color, $btn-danger-border);

  &.disabled,
  &:disabled {
    background-color: $btn-danger-bg;
  }

  &.readonly {
    @include link-active {
      background-color: $btn-danger-bg;
    }
  }
}

.btn-secondary {
  @include btn-special($btn-secondary-bg, $btn-secondary-color, $btn-secondary-border);

  &.disabled,
  &:disabled {
    background-color: #009776;
  }

  &.readonly {
    @include link-active {
      background-color: $btn-secondary-bg;
    }
  }
}

.btn-info {
  @include btn-special($btn-info-bg, $btn-info-color, $btn-info-border);

  &.disabled,
  &:disabled {
    background-color: #85d6d0;
  }
}

.btn-icon {
  @include btn-special(transparent, $white, transparent, false);

  padding: 4px 8px;
  font-size: $h4-size;
  margin: 0 4px;

  @include link-active {
    background-color: $royal-blue;
    border: solid 1px $sky-blue;
    color: $white;
  }

  &:active {
    background-color: $body-blue;
    border: solid 1px #000b14;
  }

  @include mobile-only {
    padding: 2px 6px;
  }
}

.btn-link {
  @include btn-special(transparent, $link-color, transparent, false);

  font-weight: $regular-font-weight;
  border-bottom: 1px solid transparent;
  padding: $padding-small-vertical $padding-small-horizontal;
  border-radius: 0;

  @include link-active {
    border-bottom: 1px solid $link-color;
  }

  &:active,
  &.active {
    color: $teal;
    border-bottom: 1px solid $teal;
  }
}

.btn-plain {
  background: transparent;
  color: inherit;
  box-shadow: none;
  border: none;
}

.btn-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0;
  border-radius: 50%;
  width: 1.6em;
  height: 1.6em;
  font-size: $small-size;
  text-align: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  padding: 0;

  @include mobile-only {
    font-size: $h5-size;
    height: $h3-size;
    width: $h3-size;
  }

  @include tablets-only {
    font-size: $h1-size;
    height: $h2-size;
    width: $h2-size;
  }

  &.btn-plain {
    background-color: transparent;
    color: $teal;
  }
}

#btn-reset-zoom {
  float: right;
  z-index: 1000;
}

.MuiButtonBase-root.MuiButton-root {
  padding: $padding-base-vertical 8px;
}

.inline-button {
  background-color: unset;
  border: none;
  padding: 0;
  text-decoration: underline;

  &:hover {
    font-weight: $bold-font-weight;
  }
}

.mobile-floating-buttons {
  bottom: 0;
  display: flex;
  position: fixed;
  width: 100%;
  padding: 12px;
  gap: 12px;

  > * {
    flex: 1;

    &:not(:first-of-type) {
      margin-left: 0;
    }
  }
}