@import "../Styles/variables";
@import "../Styles/mixins";

.cookies-popup-wrapper {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1400;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 40px);
  max-width: 600px;
  border-radius: 10px;

  @include mobile-only {
    bottom: 0;
    width: 100%;
    border-radius: 0;
  }
}

.cookies-popup-text {
  margin-bottom: 16px;
  text-align: center;
}

.cookies-popup-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  width: 50%;
  gap: 3rem;

  @include mobile-only {
    width: 90%;
    gap: 2rem;
  }

  .btn {
    flex: 1;
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
