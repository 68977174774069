@import '../../Styles/variables';
@import '../../Styles/mixins';

.pricing-plan-option {
  align-items: center;
  border: 2px solid $teal;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  height: 7rem;
  justify-content: space-between;
  padding: 0 16px;
  flex: 1;
  @include mobile-only {
    padding: 0 12px;
    height: 6rem;
    border-radius: 5px;
  }

  > {
    flex: 0;
  }

  .MuiSelect-select {
    &.MuiSelect-outlined {
      &.MuiInputBase-input {
        &.MuiOutlinedInput-input {
          padding-right: 0;
        }
      }
    }
  }

  .pricing-plan-label {
    flex: 1;
    font-size: $h5-size;

    @include mobile-only {
      font-size: $h5-size;
    }
  }

  .pricing-plan-content {
    padding: 0;
    width: 50%;
    @include mobile-only {
      width: 40%;
    }
  }

  .pricing-plan-content .form-group {
    margin-bottom: unset;
  }

  .pricing-plan-content .form-group > label {
    display: none;
  }

  .select.input > option {
    background-color: $body-blue;
    border: none;
  }
}

.pricing-info-total {
  text-align: right;

  .pricing-info-total-number {
    font-size: $h2-size;
  }
}

.pricing-options-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  padding-bottom: 12px;
}