@import '../Styles/variables';
@import '../Styles/mixins';

.MuiStepper-root {
  justify-content: space-evenly;
  padding: 20px;

  @include mobile-only {
    padding: 0;
  }
}

.MuiStepConnector-root {
  &.MuiStepConnector-horizontal {
    margin-bottom: 12px;
    margin-top: auto;
  }

  &.Mui-completed,
  &.Mui-active {
    > .MuiStepConnector-line {
      border-color: $teal;
    }
  }

  &.Mui-disabled > .MuiStepConnector-line {
    border-color: rgba(255, 255, 255, 0.56);
  }
}

.MuiStep-root {
  &.MuiStep-horizontal {
    flex: 0;
  }

  .MuiStepLabel-iconContainer {
    padding: 0;

    > svg {
      fill: rgba(255, 255, 255, 0.56);
    }
  }

  &.Mui-completed,
  &.Mui-active {
    .MuiStepLabel-iconContainer > svg {
      fill: $teal;
    }
  }
}

.MuiStepLabel-root.MuiStepLabel-horizontal {
  display: flex;
  flex-direction: column-reverse;

  .MuiStepLabel-label {
    transform: translate(-50%, 0);
    white-space: nowrap;
    color: $baby-blue;
    font-weight: $bold-font-weight;
    font-size: $small-size;
    margin-bottom: 8px;
    text-transform: uppercase;
  }
}


.MuiStepLabel-root {
  margin-top: 24px;
  position: relative;

  > .MuiStepLabel-labelContainer {
    display: flex;
    position: absolute;
    top: -24px;
    left: calc(-50% + 24px);
    right: calc(50% - 20px);
  }
}

.stepper-actions {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin: auto 0 0 auto;
  @include mobile-only {
    padding-bottom: 20px;
    justify-content: space-between;
    margin: 0;
    width: calc(100% - 32px);
    position: absolute;
    bottom: 0;
  }

  & > button {
    @include mobile-only {
      width: 100%;
      padding: 10px;
    }
  }
}

.stepper-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px 0;

  @include mobile-only {
    padding: 24px 16px;
  }
}

.step-subtitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 10px 0 10px;
}

.header-absolute-title {
  @include mobile-only {
    position: fixed;
    z-index: 1300;
    font-size: $h5-size;
    top: 14px;
    right: 25px;
  }
}