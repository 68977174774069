.alert {
  padding: 0;
  border-left: 0;
  border-right: 0;
  margin-bottom: 0;

  .close {
    opacity: 0.8;
    text-shadow: none;
  }
}

.alert-dismissible {
  padding-right: 35px;
}
