// - Colors --------------
// =======================
@use "sass:math";

$black: #4d4d4d;
$white: #eeeeee;

$blk-blue: #001321;
$body-blue: #00243f;
$dark-blue: #001a2e;
$card-blue: #033c70;
$marine-blue: #002e51;
$royal-blue: #005795;
$sky-blue: #308dd9;
$baby-blue: #80c2ff;

$light-teal: #94e7db;
$teal: #00d1a4;

$alert: #e51b00;
$warning: #f8b700;
$info: #00d1a4;

$link-color: $white;

$light-gray: #939393;

// - Typography ----------
// =======================
$primary-font-family: 'Roboto', Helvetica, sans-serif;

$black-font-weight: 900;
$bold-font-weight: 700;
$semi-bold-font-weight: 500;
$regular-font-weight: 400;

$h1-size: math.div(36, 14) * 1em;
$h2-size: math.div(28, 14) * 1em;
$h3-size: math.div(22, 14) * 1em;
$h4-size: math.div(18, 14) * 1em;
$h5-size: math.div(16, 14) * 1em;
$p-size: 1em;
$small-size: math.div(12, 14) * 1em;
$ultra-small-size: math.div(10, 14) * 1em;

$fa-font-path: "/fonts";

// - animation timing ---
// =======================
$ease-out: cubic-bezier(.15, .82, .52, 1.1);

// - Grid & Paddings -----
// =======================
$grid-gutter-default: 15px;

// - Buttons -------------
// =======================
$btn-font-weight: $bold-font-weight;
$btn-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
$btn-shadow-hover: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 3px 6px 1px rgba(0, 0, 0, 0.3), inset 0 1px 0 0 rgba(255, 255, 255, 0.5);

$btn-default-color: $blk-blue;
$btn-default-bg: $teal;
$btn-default-border: $btn-default-bg;

$btn-primary-color: $btn-default-color;
$btn-primary-bg: #04cca0;
$btn-primary-border: $btn-primary-bg;

$btn-secondary-color: $btn-default-color;
$btn-secondary-bg: $light-teal;
$btn-secondary-border: $light-teal;

$btn-info-color: $btn-default-color;
$btn-info-bg: #85d6d0;
$btn-info-border: $btn-info-bg;

// - Inputs --------------
// =======================
$input-bg: #000000;
$input-color: $white;
$input-border: $card-blue;
$input-border-focus: rgba($teal, 0.7);

// - Header Row ----------
// =======================
$header-row-height: 64;
$left-rail-width: 279;

// - Modal ----------
// =======================
//** Padding applied to the modal body
$modal-inner-padding: 30px;

//** Padding applied to the modal title
$modal-title-padding: 8px 15px;
//** Background color of modal content area
$modal-content-bg: $card-blue;
//** Modal content border color
$modal-content-border-color: solid 1px #002038;
//** Modal content border color **for IE8**
$modal-content-fallback-border-color: #002038;
//** Modal header border color
$modal-header-border-color: transparent;
//** Modal backdrop opacity
$modal-backdrop-opacity: .8;

$modal-header-height: 40;

$modal-sm: 380px !default;

// - Drop Down ----------
// =======================
//** Background for the dropdown menu.
$dropdown-bg: $blk-blue;
//** Dropdown menu `border-color`.
$dropdown-border: transparent;
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border: transparent;
//** Divider color for between dropdown items.
$dropdown-divider-bg: $blk-blue;
//** Menu divider color
$dropdown-divider-color: rgba(4, 204, 160, 0.20);

//** Dropdown link text color.
$dropdown-link-color: $white;
//** Hover color for dropdown links.
$dropdown-link-hover-color: $white;
//** Hover background for dropdown links.
$dropdown-link-hover-bg: $card-blue;

//** Hover color for links.
$link-hover-color: $white;
//** Hover decoration for links
$link-hover-decoration: none !default;

//** Active dropdown menu item text color.
$dropdown-link-active-color: $white;
//** Active dropdown menu item background color.
$dropdown-link-active-bg: $card-blue;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color: rgba($white, 0.6);

//** Text color for headers within dropdown menus.
$dropdown-header-color: $light-teal;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color: $dropdown-link-color;

// - Drop Down ----------
// =======================
$jumbotron-color: $white;
$jumbotron-bg: $card-blue;
$jumbotron-heading-color: inherit;
$jumbotron-font-size: $p-size;

// - Popover ------------
// ======================
$popover-bg: rgba(0, 0, 0, 0.8);
$popover-color: $white;
$popover-border-color: transparent;

//== Alerts
//
//## Define alert colors, border radius, and padding.
$alert-padding: 15px;
$alert-border-radius: 0;
$alert-link-font-weight: bold !default;

$alert-warning-bg: $warning;
$alert-warning-text: $black;
$alert-warning-border: $white;

$alert-info-bg: $info;
$alert-info-text: $black;
$alert-info-border: $white;

