.type-small-regular {
  font-size: $small-size;
  font-weight: $regular-font-weight;
  letter-spacing: 1px;
}

.type-regular {
  font-size: $p-size;
  font-weight: $regular-font-weight;
  letter-spacing: 1px;
}

.type-large-regular {
  font-size: $h5-size;
  font-weight: $regular-font-weight;
  letter-spacing: 1px;
}


.modal-row-title {
  font-size: $p-size;
  color: #00d1a4;
  padding-bottom: 2px;
  margin-top: 5px;
  text-transform: capitalize;
}
