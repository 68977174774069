@import "../../Styles/variables";
@import "../../Styles/mixins";

.alerts-history-main {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);

  .main-panel {
    overflow: hidden;
    padding: 8px;
  }

  @include tablets-only {
    margin-bottom: 2rem;
  }

  @include mobile-only {
    background: $dark-blue !important;
    max-height: calc(100vh - 48px);
    height: calc(100vh - 48px);
    overflow: hidden;
    padding: unset;

    .main-panel {
      overflow: scroll;
    }
  }

  @include mobile-only {
    .device-list-mobile {
      background: $body-blue !important;
      display: flex;
      gap: 10px;
      padding: 12px 15px;
      font-size: 1.5rem;
      margin-left: -3px;
    }
  }

  .main-panel {
    flex: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 1rem;

    @include tablets-only {
      background: $body-blue;
    }

    &.no-results {
      padding-top: 100px;
    }
  }

  .filters-wrapper {
    display: flex;
    background: $marine-blue;
    min-height: em($header-row-height);

    flex-wrap: wrap;
    gap: 15px 0;
    padding: 15px 0;
    @include mobile-only {
      display: none;
    }
  }

  .pagination-wrapper {
    display: flex;
    margin: 10px auto;
    @include mobile-only {
      display: none;
    }
  }

  .pagination-text-showing {
    padding: 0 8px;

    @include mobile-only {
      display: none;
    }
  }

  .pagination-button {
    text-align: center;
  }

  .angle-icon-pagination {
    font-size: 18px;
    font-weight: bold;
    align-self: center;
  }
}

.alerts-details-button {
  @include mobile-only {
    width: 30% !important;
  }
}

.alert-history-details-modal {
  .top-wrapper {
    flex: 1;
    display: flex;
    background: $marine-blue;
    padding: 10px;
    justify-content: space-between;

    .top-wrapper-right {
      gap: 24px;
      display: flex;
    }
  }

  .alert-details {
    flex: 1;
    display: flex;
    padding: 15px 12px;
    justify-content: space-between;

    @include mobile-only {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }

    .alert-detail-column {
      display: flex;
      flex: 1;
      flex-direction: column;

      &.date-column {
        flex: 1.25;
      }

      &.alert-value-column {
        flex: 0.75;
      }

      &.condition-column {
        flex: 1.75;
      }
    }
  }

  .alert-details-notes-list {
    overflow-y: auto;

    @include desktop-only {
      max-height: 20rem;
    }

    @include tablet-and-mobile {
      max-height: 30vh;
    }

    .alert-details-history-note {
      font-size: 12px;
      padding: 0.5em 0;
    }
  }

  .zoom-wrapper {
    padding: 0 12px 15px 12px;
    margin-bottom: 20px;
  }

  .no-graph-data {
    padding: 12px;
  }
}

.notes-row {
  padding: 1em 0.75em;
}

.alert-details-body {
  padding: unset !important;
}

.select-filters-wrapper {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
}

.alerts-history-table-head {
  background: $body-blue !important;
}

.page-button {
  font-size: 16px !important;
}

.u-degree-history {
  font-size: 0.7em;
  vertical-align: text-top;
}

.alert-history-nav .input-label {
  margin: 1em 0 0.5em;
  padding-left: 1.5em;
}

.alerts-history-desktop-table {
  display: block;
  overflow: auto;

  &::-webkit-scrollbar {
    background-color: $marine-blue;
    position: absolute;
    width: 10px;
    margin-right: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(128, 194, 255, 0.4);
    border-radius: 6px;
    width: 8px !important;
  }

  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  thead {
    height: 70px;
  }

  @include tablet-and-mobile {
    display: none;
  }
}

@include tablets-only {
  .tablet-drawer {
    .MuiPaper-root {
      overflow-x: hidden;
    }
  }
}

.filters-mobile-icon {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: 12px;
  gap: 5px;

  button {
    display: flex;
  }
}

.filters-wrapper-mobile-tablet {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  padding: 12px;

  @include tablets-only {
    background: $marine-blue;
  }

  .device-name {
    display: flex;
    align-items: center;
    font-size: 1.75rem;

    button {
      display: flex;
    }
  }

  .from {
    padding-left: 15px;
  }

  .to {
    padding-left: 15px;
  }

  .input-filter-holder {
    margin: 6px;
  }

  .input-holder-icon {
    background: $dark-blue !important;
  }
}

.mobile-tablet-segments {
  display: flex;
  flex-direction: column;

  .notification-segment {
    align-items: center;
    display: flex;
    gap: 8px;
    padding: 12px 16px;
    background: $marine-blue;
    border-bottom: 1px solid $royal-blue;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    > .notification-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      width: 100%;
    }

    @include tablets-only {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .notification-content-mobile-column {
      height: 100%;
      padding: 4px;
    }

    .see-details-button {
      padding: unset;
      span {
        margin-top: 6px;
      }
    }

    .notification-label {
      padding-bottom: 4px;
      color: $baby-blue;
      letter-spacing: 1px;
      font-weight: $semi-bold-font-weight;
      font-size: $small-size;
      text-transform: uppercase;
    }

    .notification-date-status,
    .notification-details {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .notification-status {
      display: flex;
      align-items: center;
      height: 20px;

      .status-indicator {
        font-size: x-large;
        padding-right: 5px;
      }

      .status-text {
        vertical-align: middle;
      }
    }

    .u-degree-history {
      font-size: 0.7em;
      vertical-align: text-top;
    }

    .notification-actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }

    .custom-icon {
      cursor: pointer;
    }
  }
}

.gateways-header {
  cursor: pointer;

  .left-rail-nav-header-title {
    align-items: center;
    display: flex;
    width: 100%;

    > .fa {
      margin: auto 0 auto auto;
    }
  }

  &.disabled {
    cursor: default;

    .left-rail-nav-header-title {
      color: $light-gray;
    }
  }
}

.alert-history-filters-modal {
  .modal-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  label.input-label {
    width: 100%;
  }

  .modal-body,
  .modal-footer,
  .modal-header {
    background-color: $dark-blue;
  }

  .modal-body > * {
    margin-bottom: 15px;
  }
}

.floating-buttons-alert-history {
  background: $dark-blue;
  justify-content: center;
}

.alerts-history-selected {
  color: #fff;
  text-transform: capitalize;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0;
}

.alerts-history-button-cancel {
  font-size: 1.5rem;
  padding: 3px 4px !important;
}

.delete-icon {
  padding: 6px 0 !important;
  min-width: 0 !important;
  margin: 0 !important;
  i {
    font-size: 24px;
  }
}
