.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-text-small {
  font-size: $small-size;
}

.u-text-blue {
  color: $baby-blue;
}

.u-text-uppercase {
  text-transform: uppercase;
}

.u-text-teal {
  color: $teal;
  stroke: $teal;
}

.u-text-light-teal {
  color: $light_teal;
}

.u-text-error {
  color: $warning;
  font-size: $small-size;
}

.u-degree {
  font-size: 0.6em;
  vertical-align: text-top;
}

.u-text-eclipse {
  @include no-wrap;
}

.u-text-bold {
  font-weight: $bold-font-weight;
}