@import '../../../Styles/variables';
@import '../../../Styles/mixins';


div.modal-dialog {
  &.modal-firmware {
    margin-top: 10vh;
    width: 800px;
  }
}

@include mobile-only {
  div.modal-dialog {
    &.modal-firmware {
      width: unset;
    }
  }
}
.modal-firmware {
  margin-top: 10vh;

  @include mobile-only {
    margin: 5vh auto !important;
    height: 100%;
    width: unset;
  }
}

.manage-firmware-modal {
  .modal-body {
    padding: 0;

    .modal-body-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .modal-body-header-latest-firmware-versions {
        display: flex;
        flex-flow: column nowrap;
        gap: 0.5rem;
        width: 100%;
      }

      .btn-select-all {
        height: fit-content;
      }
    }

    .gateway-tab-select-all-buttons {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
    }

    .latest-firmware-version {
      font-size: $h5-size;
      margin-top: 0;
      display: flex;
      flex-direction: column;
    }

    .select-group {
      .header {
        .centered-header {
          text-align: center;
        }
      }

      max-height: 50rem;

      @include mobile-only {
        box-shadow: none;
      }

      @include tablets-only {
        max-height: 40rem;
      }

      .select-group-item:nth-child(n+2) {
        .checkbox-cell {
          text-align: center;

          .disabled-checkbox {
            padding: 0;
            pointer-events: none;

            span {
              display: none;
            }
          }
        }
      }
    }
  }

  .modal-footer {
    .disabled-button {
      background: darkgrey;
      border: slategrey;
      pointer-events: none;
      opacity: 0.4;
    }
  }
}

.nav-item > a.nav-link {
  cursor: pointer;
}

.mobile-tab {
  @include mobile-only {
    width: 50%;
  }
}

.mobile-list {
  .input-label {
    padding-right: 6px;
    font-size: $p-size;

    + div {
      font-size: $h5-size;
    }
  }

  .mobile-list-row {
    align-items: baseline;
    padding: 4px 0;
  }
}

.mobile-header-row {
  align-items: baseline;
  justify-content: flex-end;
  color: $white;

  span {
    margin-left: 5px;
  }
}

.mobile-search {
  @include mobile-only {
    padding: 10px 0;
    width: 100%;
  }
}