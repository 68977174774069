@import "../../../Styles/variables";
@import "../../../Styles/mixins";

.br-on-mobile {
  display: none;

  @include mobile-only {
    display: unset;
  }
}

.alert-modal-main {
  display: block;
  opacity: 1;

  .MuiStepper-root {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@include mobile-only {
  .modal-footer {
    font-size: 1rem;

    button {
      margin-left: 0 !important;
    }
  }
}

.alert-modal-body {
  padding: 20px !important;
}

.notes-step {
  .notes-list {
    background: #074377;
    padding: 6px 12px 12px 12px;
    max-height: 210px;
    overflow-y: auto;
    margin-bottom: 1rem;

    .history-note {
      padding: 5px 0;
    }
  }

  .save-note-button-wrapper {
    display: flex;
    width: 100%;
    justify-content: end;

    .save-note-button {
      text-transform: uppercase;
    }
  }
}

.alert-acknowledgement-note-timestamp-user {
  color: $light-gray;
}

.alert-acknowledgement-note-message {
  font-weight: 600;
}

.aside {
  .aside-header-alert-name {
    font-weight: 700;
    font-size: 14px;
    line-height: 16.5px;
    color: white;
    margin-bottom: 0.5rem;
  }

  .alert-info-alarm-icon-wrapper {
    padding-left: 0.75rem;
  }

  .alert-unit {
    padding-left: 0.25rem;
  }
}

@include mobile-small {
  div.info {
    flex-direction: column;
  }

  div.info-alert {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  p#alert-condition {
    order: 2;
    flex-basis: 80%;
  }

  p#datetime-mobile {
    order: 1;
    margin-left: auto;
    white-space: pre-line;
  }

  div.info-device {
    text-align: left;
  }

  div.info-device {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    padding-top: 0;
  }

  p#info-device-device {
    flex-basis: 80%;
    order: 2;
  }

  label.input-label {
    font-size: 12px;
  }
}
