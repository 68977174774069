@import '../Styles/variables';
@import '../Styles/mixins';

.MuiCard-root.card-root {
  border-radius: 0 !important;
  color: #fff;
  display: flex;
  flex-direction: column;
}

.MuiCard-root > .MuiCardHeader-root {
  background: linear-gradient(180deg, #0080dd 0%, #0060a5 100%);
  color: #fff;
  padding: 6px 16px;
}

.MuiCardContent-root.card-content-root {
  background: $body-blue;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  justify-content: space-between;
  padding: 24px;

  @include mobile-only {
    padding: 0;
  }
}