@import "../../Styles/mixins";
@import "../../Styles/variables";

.modal {
  display: flex;
  opacity: 1;
  z-index: 1400;
}

.modal-dialog.full {
  display: flex;

  .modal-content {
    width: inherit;
    flex: 1;
  }
}

@include tablet-and-mobile {
  .modal {
    display: flex;

    .modal-dialog {
      align-items: center;
      margin: auto;
      max-height: 90vh;
      overflow: hidden;
      width: 90vw;

      .modal-content {
        height: inherit;
        max-height: inherit;
      }
    }
  }
}

.modal-content {
  background-image: radial-gradient(circle at 50% 0, #1565b0, #02386b);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3), 0 1px 2px 1px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  min-height: em(207);

  .react-datepicker__time-list {
    width: fit-content !important;
  }
}

.modal-header {
  flex: 0;
  background-color: $marine-blue;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #002037;
  overflow-wrap: break-word;

  .close {
    color: $white;
    opacity: 0.8;
  }
}

.modal-title {
  flex: 1;
  font-size: $h5-size;
  font-weight: $semi-bold-font-weight;
}

.modal-body {
  letter-spacing: 1.2px;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;

  @include mobile-only {
    padding: 12px;
  }
}

@media (min-width: $screen-md-min) {
  .modal-el {
    width: 80vw;
  }
}

.modal-footer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
  width: 100%;
  padding: 24px;
  border-color: $dark-blue;

  @include mobile-only {
    justify-content: space-between;
    margin: 0;
    width: 100%;
    padding: 16px 8px;
    border-top: unset;
  }

  & > button {
    @include mobile-only {
      width: 100%;
      padding: 10px 0;
    }
  }
}

.legal-modal {
  height: 75vh;
  overflow-y: auto;

  .legal-checkbox {
    margin: auto;
    margin-right: 0;
  }

  a {
    color: $sky-blue;
  }
}

.checkbox-accept-terms {
  margin: auto;
  margin-left: 0;
}

.legal-modal-dialog {
  background: $body-blue !important;

  .modal-content {
    background: $body-blue !important;
  }

  .modal-body {
    overflow-y: hidden;
  }

  .modal-header {
    background: $royal-blue !important;
  }

  .modal-footer {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: start;

    @include desktop-only {
      display: flex;
      justify-content: end;

      .legal-checkbox-wrapper {
        margin: auto;
        margin-left: 0;
      }
    }

    .checkbox-data-transfer-wrapper {
      display: flex;
      text-align: left;
      width: 75%;

      @include mobile-only {
        width: 95%;
      }
    }
  }

  @include desktop-only {
    width: 800px !important;
  }

  @include tablets-only {
    width: 80% !important;
  }

  @include small-desktop {
    width: 80% !important;
  }

  @include mobile-only {
    max-height: 100% !important;
    width: 100vw !important;
    height: 100vh !important;
  }

  .close-accept-buttons-wrapper {
    display: flex;
    text-align: justify;
    justify-content: end;

    @include mobile-only {
      width: 100%;
      justify-content: center;
      gap: 2rem;

      button {
        width: 45%;
      }
    }
  }

  .checkbox-accept-terms {
    display: flex;

    @include mobile-only {
      text-align: left;
    }
  }

  .legal-button {
    padding: 1rem 3rem !important;
    @include desktop-only {
      max-height: 4rem;
    }
  }
}

.modal-lg {
  .modal-content {
    min-height: 500px;
  }
}

@include mobile-only {
  .modal-dialog.modal-lg {
    margin: 0;
    max-height: 100%;
    height: 100%;
    width: 100vw;
  }
}

.modal-tabs {
  background-color: $marine-blue;

  @include mobile-only {
    background-color: $dark-blue;
  }

  .MuiTabs-indicator {
    background-color: unset;

    @include mobile-only {
      background-color: $teal;
    }
  }

  .MuiTab-root {
    border-bottom: none;
    color: $teal;

    @include mobile-only {
      color: rgba($teal, 0.6);
    }

    &.Mui-selected {
      background-color: $royal-blue;

      @include mobile-only {
        background-color: unset;
      }
    }
  }
}

.modal .left-rail {
  height: unset;
}
