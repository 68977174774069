@import '../Styles/variables';

.right-panel {
  position: relative;
}

.manage-help-modal .btn.delete-button {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1000;
}

.add-button {
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

.toggle-row {
  display: flex;
  justify-content: space-between;
}

.custom-file-upload {
  cursor: pointer;
  padding: 1rem;
  padding-top: 0;
  border: 1px solid $teal;
  border-radius: 5px;
  min-width: 50%;

  .custom-file-name {
    margin: 1rem 0;
    display: flex;
  }

  ::-webkit-file-upload-button {
    display: none;
  }

  ::file-selector-button {
    display: none;
  }

  .upload-icon {
    margin-right: 1rem;
  }
}

.help-tab-modal {
  a {
    color: blue !important;
    text-decoration: underline !important;
  }

  a:hover {
    color: #c8c8ff !important;
  }
}

.ql-editor {
  min-height: 200px;
}