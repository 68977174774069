@import "../../Styles/variables";
@import "../../Styles/mixins";

.message-notification {
  .input-holder {
    textarea {
      padding: 10px;
    }
  }
}

.maintenance-notification-snackbar {
  width: 100vw;

  .close-button {
    background-color: $alert;
    color: white;
    font-size: 15px;
    border: none;
  }
}

.message-edition-row {
  margin-bottom: 2rem;
}

@include mobile-only {
  .manage-system-messages-modal .row {
    margin-right: 0 !important;
    margin-left: 5px !important;
  }

  .last-invalidation-time {
    display: flex;
    padding-top: 5px;
    padding-left: unset !important;
  }
}

.permissions-collapsible {
  margin-bottom: 16px;
  overflow: auto !important;
}
