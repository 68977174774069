@import "./variables";

.list-plain {
  padding-left: 0;
  margin-left: 0;
  list-style: none;

  li {
    padding: 0;
    margin-left: 0;
  }
}

.mobile-list {
  background-color: $marine-blue;

  .mobile-list-item {
    padding: 1em;
    
    &:not(:first-of-type) {
      border-top: 2px solid $royal-blue
    }
  }
}

