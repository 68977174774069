@import '../Styles/variables';

.digits-input {
  display: flex;
  gap: 24px;
  justify-content: center;
  padding: 24px 0;
  position: relative;

  > input {
    font-feature-settings: "tnum";
    font-size: $h1-size;
    font-variant-numeric: tabular-nums;
    letter-spacing: 0;
    text-align: justify;
    width: 100%;

    &:after {
      content: "";
      display: inline-block;
      width: 100%;
    }
  }

  .input-cover {
    background-color: $body-blue;
    height: 5em;
    position: absolute;
    width: 16px;
    z-index: 5;
  }
}
