@import "../../Styles/variables";

.invoice-table {
  tr, th, td {
    font-size: 12px;
  }

  th {
    padding: 8px 16px;
  }

  tr {
    td {
      padding: 10px 16px;
      text-transform: none;

      &:last-of-type {
        text-align: right;
      }

      &:nth-child(1) {
        max-width: 140px;
      }

      &:nth-child(2) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
      }
    }
  }
}

.invoice-list {
  width: 100%;

  .MuiListItem-root {
    background-color: $marine-blue;
    flex-direction: column;
    max-width: 100%;
    padding: 8px;
    gap: 4px;

    &:not(:first-of-type) {
      border-top: 1px solid $royal-blue;
    }
  }

  .invoice-item {
    display: flex;
    flex: 1;
    gap: 4px;
    overflow: hidden;
    text-overflow: ellipsis;

    > *:not(.input-label) {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.invoice-table,
.invoice-list {
  a {
    background-color: unset;
  }
}
