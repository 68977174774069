.phone-input-wrapper {
  align-items: flex-end;
  display: flex;
  gap: 8px;
  margin-bottom: 15px;

  .MuiSelect-select {
    &.MuiSelect-outlined {
      &.MuiInputBase-input {
        &.MuiOutlinedInput-input {
          padding-right: 20px;
        }
      }
    }
  }

  > *:first-child {
    width: 40%;
  }

  > *:last-child {
    width: 60%;
  }

  .form-group {
    margin-bottom: 0;

    &:last-child {
      width: inherit;
    }

    > label {
      display: none;
    }
  }
}