.alert-log-heading {
  color: $light-teal;
  font-size: em(20);
  font-weight: $bold-font-weight;
  text-align: center;
  margin-bottom: 0 !important;
}

.alert-log-subheading {
  color: $light-teal;
  font-size: em(12);
  font-weight: $black-font-weight;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
}

.alert-log-time {
  font-size: em(10);
  color: $light-teal;
  font-weight: $regular-font-weight;
  text-align: center;
  white-space: break-spaces;
}
