@import '../Styles/variables';
@import '../Styles/mixins';

$datepicker-bg: $blk-blue;
$datepicker-btn-color: transparent;
$datepicker-border-color: transparent;
$datepicker-btn-active: $royal-blue;

.datepicker-dropdown {
  .well {
    background-color: $datepicker-bg;
    border: none;
    border: 0;
  }

  .glyphicon {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .glyphicon-chevron-left:before {
    content: '\f053';
  }

  .glyphicon-chevron-right:before {
    content: '\f054';
  }

  .text-muted {
    color: #9a9a9a;
  }

  .text-info {
    color: $baby-blue;
  }

  .btn {
    background-color: $datepicker-btn-color;
    color: $white;
    border-color: $datepicker-border-color;
    // border-radius: 0px;
    box-shadow: none;
    padding: 6px 8px;

    &.active {
      background-color: $datepicker-btn-active;
      color: #fff;

      & > span {
        color: #fff !important;
      }
    }

    &.disabled {
      opacity: 0.4;
    }

    &:not(.disabled):hover {
      background-color: $card-blue;
      color: #fff;

      & > span {
        color: #fff;
      }
    }
  }

  thead {
    th:nth-child(2) .btn {
      color: $baby-blue;
    }

    .btn {
      font-size: 1em;
      text-transform: uppercase;

      &.disabled {
        opacity: 1;
      }
    }
  }

  &-timeSelector {
    padding: 0 15px 9px 15px;

    .input-holder {
      width: 30%;
    }
  }
}

.datepicker {
  &.error .dropdown-menu {
    box-shadow: 0 0 3px rgba($warning, 0.5);
  }

  .dropdown-menu {
    box-shadow: 0 0 3px rgba($baby-blue, 0.5);
  }

  .u-text-error {
    padding: 10px 15px 0;
  }
}

.react-datepicker {
  padding: 5px 0;
  font-size: 14px;
  background-color: #001321;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 0 3px rgb(128 194 255 / 50%);
  z-index: 1000;
  width: max-content;

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #fff;
    font-size: 12px;
    line-height: 30px;
    width: 30px;
    font-family: Roboto, sans-serif;
  }

  .react-datepicker__time {
    background-color: #001321;
    color: #fff;
  }

  .react-datepicker__header {
    background-color: #001321;
    border-bottom: 1px solid #aeaeae;
    padding: 0;
  }

  .react-datepicker__current-month {
    color: #fff;
  }

  &-popper {
    z-index: 20;
  }
}
