@import '../../Styles/variables';
@import '../../Styles/mixins';

.link {
  color: $baby-blue;
  letter-spacing: 1px;
  font-weight: $bold-font-weight;

  &:hover {
    cursor: pointer;
  }
}

.email-link {
  padding-top: 16px;
}

.billing-buttons {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: 16px;

  @include mobile-only {
    flex-direction: column;
    margin-top: 0;

    > button {
      width: max(70%, 200px);
    }
  }
}

@include mobile-only {
  .billing-modal-footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    button {
      width: 50%;
    }
  }
}

.modal-billing-tabs {
  padding: 0 !important;
  .MuiTabs-flexContainer {
    background: $card-blue;
  }
  .Mui-selected {

  }
}
