@import '../../Styles/mixins';
@import '../../Styles/variables';


.users-tab-root {
  overflow: hidden;
  min-height: 48px;
  display: flex;
  overflow: unset;
  background-color: #002e51;

  .users-tab-scroller {
    position: relative;
    display: inline-block;
    flex: 1 1 auto;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    margin-bottom: 0;

    .users-tab-container {
      display: flex;

      .users-tab-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        background-color: transparent;
        outline: 0;
        border: 0;
        margin: 0;
        border-radius: 0;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        text-decoration: none;
        font-family: Roboto, Helvetica, sans-serif;
        font-weight: 500;
        line-height: 1.25;
        text-transform: uppercase;
        min-width: 90px;
        min-height: 48px;
        -webkit-flex-shrink: 0;
        flex-shrink: 0;
        padding: 12px 16px;
        overflow: hidden;
        white-space: normal;
        text-align: center;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        color: rgba(4, 204, 160, 0.60);
        font-size: 1em;
        max-width: unset;
        width: 50%;
        border-bottom: 1px solid rgba(4, 204, 160, 0.20);

        &.selected {
          color: #04cca0;
          border-bottom: 1px solid rgba(4, 204, 160, 1);
        }

        .users-tab-touch {
          overflow: hidden;
          pointer-events: none;
          position: absolute;
          z-index: 0;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: inherit;
        }
      }
    }
  }
}

.add-user-plus-button {
  display: flex;
  gap: 4px;

  @include tablets-only {
    margin-right: 20px;
  }
}

.toggle-wrapper {

  .toggle {
    display: flex;
    align-items: center;
  }

  .dnd-tooltip {
    margin-left: 12px;
    margin-top: 4px;
    white-space: nowrap;
  }
}

.user-nav-toggle {
  width: 100%;
  padding: 1em 0;
}

.reset-password-tablet {
  align-items: baseline !important;
  margin-top: 18px !important;
  @include mobile-only {
    display: flex;
    margin: 0 !important;
  }
}

.drawer-header {
  @include tablets-only {
    padding-bottom: 10px;
  }
}


.autocomplete-users {
  margin: 10px 15px 15px 10px;

  label:not(.Mui-focused) {
    top: -0.65em !important;
  }

  .MuiAutocomplete-endAdornment {
    top: calc(50% - 10px) !important;
  }
}

.header-container {
  display: flex;
  flex-direction: column;
}

.user-mobile-header {
  flex-direction: column;
  background-color: #002e51;
}

.mobile-control-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 16px;
}

.sorting-radio-wrapper {
  padding: 0 15px 0 20px;
  color: $white;
  max-width: 50vw;
  white-space: break-spaces;
  font-size: $p-size;
}

.users {
  .users-main {
    @include mobile-only {
      padding-top: 30px;
      padding-bottom: 0;
    }
  }
}

.mobile-edit-user,
.mobile-delete-user {
  position: absolute;
  font-size: 20px;
  z-index: 2;
}

.mobile-edit-user {
  right: 48px;

  .btn {
    padding-top: 4px;
  }
}

.mobile-delete-user {
  right: 10px;
}

.mobile-bottom-buttons {
  flex-direction: row;
  display: flex;
  justify-content: space-between;

  button {
    @include mobile-only {
      padding: 0 0 10px 0 !important;
      align-self: end;
      display: flex;
    }
  }
}

.dnd-mobile-button {
  white-space: nowrap;

  .toggle {
    margin-left: -10px;
  }
}

.account-select {
  @include mobile-only {
    padding: 0 15px;
  }
}

.MuiAutocomplete-listbox {
  padding: 0 !important;
}

.user-properties-modal .user-permission {
  > div {
    display: flex;
  }
}