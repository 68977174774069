@import "../Styles/variables";
@import "../Styles/mixins";

.MuiPaper-root {
  background: $marine-blue;
  color: $white;
  border-bottom: 1px solid $royal-blue;
  &.Mui-expanded {
    border-bottom: unset;
  }

  .MuiAccordionSummary-root {
    background: $marine-blue;
    color: $white;
    &.Mui-focusVisible {
      background: $marine-blue;
    }
  }
}

.MuiButtonBase-root {
  background: $marine-blue;
  color: $white;
}

.MuiAccordionDetails-root {
  background: $marine-blue;
  color: $white;
  border-bottom: 1px solid $royal-blue !important;
  padding: 0 4px 4px 4px !important;
}

.MuiAccordion-root {
  border-radius: unset !important;
  margin: unset !important;
}

.MuiAccordionSummary-content {
  margin-top: 12px !important;
  margin-bottom: 0px !important;

  &.Mui-expanded {
    margin: unset !important;
    margin-top: 12px !important;
  }

  p {
    width: 60%;
  }
}
