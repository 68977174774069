.checkbox {
  display: inline-block;;
  position: relative;
  vertical-align: middle;
  padding-left: 24px;
  font-weight: $regular-font-weight;
  margin: 0;

  & > .input-checkbox + span {
    border-radius: 2px;
    height: 1em;
    width: 1em;
    border: solid 1px $white;
    display: inline-block;
    margin-right: 0.68em;
    vertical-align: middle;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  & > .input-checkbox:checked + span {
    @extend .fa;
    @extend .fa-check-square;
    color: $white;
    font-size: 16px;
    border: 0 solid transparent;
    margin-left: -3px;
  }

  &.isSaving > .input-checkbox + span,
  &.isSaving > .input-checkbox:checked + span {
    @extend .fa;
    @extend .fa-spinner;
    @extend .fa-spin;
    color: $teal;
    border: none;
    top: 0;
  }
}


.input-label-checkbox,
.input-label-radio {
  font-weight: $regular-font-weight;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  padding-left: 24px;


  &:not(.checkbox-hidden-label) {
    &:before {
      margin-right: 0.68em;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &.checkbox-hidden-label {
    height: 1em;
    padding: 0;
    width: 1em;
  }

  &:before {
    content: '';
    border-radius: 2px;
    height: 1em;
    border: solid 1px $white;
    display: inline-block;
    vertical-align: middle;
    width: 1em;
  }

  &.isSaving:before {
    @extend .fa;
    @extend .fa-spinner;
    @extend .fa-spin;
    color: $teal;
    border: none;
    top: 0;
  }

  &.readonly {
    opacity: 0.6;

    &:before {
      display: none;
    }
  }
}

.input-label-radio::before {
  border-radius: 50%;
}

.input-checkbox,
.input-radio {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: -9999;
  width: 0;
  height: 0;

  &:checked + .input-label-checkbox:not(.isSaving) {
    &:before {
      @extend .fa;
      @extend .fa-check-square;
      color: $white;
      font-size: 14px;
      border: 0 solid transparent;
      height: 1em;
      width: 1em;
      padding: 0;
    }

    &.readonly {
      opacity: 1;

      &:before {
        @extend .fa-check;
        color: $teal;
      }
    }
  }

  &:checked + .input-label-radio {
    &:before {
      @extend .fa;
      @extend .fa-check-circle;
      color: $white;
      font-size: 16px;
      border: 0 solid transparent;
    }

    &.readonly {
      opacity: 1;

      &:before {
        @extend .fa-check;
        color: $teal;
      }
    }
  }

  &.isSaving {
    pointer-events: none;

    & + .input-label-checkbox {
      color: $teal;

      &:before {
        @extend .fa-circle-o-notch;
        @extend .fa-spin;
        color: $teal;
        margin-right: 0;
      }
    }
  }
}

.input-checkbox + label.input-label-checkbox.error:not(.isSaving)::before {
  color: $alert;
}
