@import "../../Styles/variables";
@import "../../Styles/mixins";

.location-dropdown {
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-top: -6px;
  display: block;

  .dropdown-toggle {
    width: 100%;
    height: 4.57143em;
    padding: 10px 15px;
    display: block;

    &:not(.disabled) {
      @include link-active {
        cursor: pointer;
        background-color: $dropdown-link-hover-bg;
      }
    }
  }

  .dropdown-body {
    width: 100%;
    background-color: $body-blue;
    padding-left: 7px;

    &.dropdown-expanded {
      height: 44vh;
      overflow-y: auto;
    }

    &-item {
      padding: 7px 12px;
      display: block;
      border-bottom: 1px solid $dark-blue;
      border-left: 1px solid $dark-blue;

      &.selected {
        background-color: #002f5b;
      }

      @include link-active {
        cursor: pointer;
        background-color: $dropdown-link-hover-bg;
      }
    }

    .no-results {
      padding: 20px 12px;
      text-align: center;
    }
  }

  .find-location {
    padding: 5px;
  }

  .location-list-header {
    display: grid;
    align-items: center;
    padding: em(3) em(3);
    border-bottom: 1px solid $teal;

    .location-list-title {
      color: $teal;
      text-transform: uppercase;
      font-size: $small-size;
      margin: 0;
      font-weight: 500;
    }

    .btn-location {
      color: $teal;
    }

    .view-all-locations {
      display: flex;
      cursor: pointer;
      color: $btn-primary-bg;
      padding: 0.25rem 0.25rem 0.25rem 1rem;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      line-height: 14.06px;
      letter-spacing: 0.06em;
      text-align: left;
      gap: 0.5rem;

      i {
        scale: 1.6;
      }
    }

    .location-list-order-row {
      align-items: center;
    }

    div {
      display: flex;
    }
  }
}
