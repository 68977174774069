@import '../Styles/variables';
@import '../Styles/mixins';

.pricing-plan-option {
  align-items: center;
  border: 2px solid $teal;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  height: 7rem;
  justify-content: space-between;
  padding: 0 16px;
  flex: 1;

  > {
    flex: 0;
  }

  .pricing-plan-label {
    flex: 1;
    font-size: $h5-size;
  }

  .pricing-plan-content {
    padding: 0;
    width: 50%;
  }

  .pricing-plan-content .form-group {
    margin-bottom: unset;
  }

  .pricing-plan-content .form-group > label {
    display: none;
  }

  .select.input > option {
    background-color: $body-blue;
    border: none;
  }
}

.pricing-info-total {
  text-align: right;

  .pricing-info-total-number {
    font-size: $h2-size;
  }
}

.pricing-options-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  padding-bottom: 12px;
}

.update-plan-card {
  border-radius: 0;
  margin: auto;
  height: 800px;
  width: 800px;
}

.update-plan-stepper {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.MuiCardActions-root {
  justify-content: end;
}

.register-step-link {
  text-decoration: underline;

  &:hover {
    font-weight: $bold-font-weight;
    text-decoration: underline;
  }
}

.header-update-plan {
  min-height: 24px;
  margin-bottom: 16px;
}

.register-success-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 15vh;
}

.register-success-message {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.06em;
  text-align: left;
  margin-bottom: 25px;
}

.chip-update-plan {
  margin-left: 1rem;
  position: relative;
  bottom: 2px;
}

.bordered-info-text {
  font-size: 12px;
  padding: 5px;

  @include desktop-only {
    font-weight: $bold-font-weight;
  }

  letter-spacing: 1px;

  &--warning {
    color: black;
    background: #f8b700;
  }

  &--error {
    color: white;
    background: red;
  }

  &--success {
    color: white;
    background: green;
  }
}