@import "../Styles/variables";

.ql-snow.ql-toolbar {
  span.ql-picker-label.ql-active,
  span.ql-picker-label:hover,
  span.ql-picker-item:hover {
    color: $teal;
  }

  span.ql-picker-label:hover,
  span.ql-picker-label.ql-active {
    .ql-stroke {
      stroke: $teal;
    }
  }

  .ql-fill,
  .ql-stroke,
  .ql-fill, {
    color: $white;
    stroke: $white;
  }

  path.ql-fill {
    fill: $white;
  }

  button:hover path.ql-fill {
    fill: $teal;
  }

  span > button {
    &.ql-active,
    &:hover {
      .ql-fill,
      .ql-stroke, {
        color: $teal;
        stroke: $teal;
      }
    }


    &.ql-active .ql-fill {
      fill: $teal;
    }
  }

  .ql-picker {
    color: $white;
  }

  .ql-picker-options {
    background-color: $marine-blue;
    color: $white;
  }
}