@import '../../Styles/variables';
@import '../../Styles/mixins';

.register-card {
  min-height: calc(100vh - 48px);
  @include desktop-only {
    position: static;
    border-radius: 0;
    margin: 25px auto;
    min-height: 800px;
    width: 800px;
  }

  @include tablets-only {
    position: static;
    border-radius: 0;
    margin: auto;
    min-height: 750px;
    width: 750px;
  }
}

.register-stepper {
  @include desktop-only {
    margin-top: 8px;
    padding: 0 30px 0 28px;
  }
}

.MuiCardActions-root {
  justify-content: end;
}

.register-form-label {
  margin-bottom: 1rem;

  &:not(:first-of-type) {
    margin-top: 1.5rem;
  }
}

.register-step-link {
  text-decoration: underline;

  &:hover {
    font-weight: $bold-font-weight;
    text-decoration: underline;
  }
}

.register-success-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 15vh;
  text-align: center;
}

.register-success-message {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.06em;
  text-align: left;
  margin-bottom: 8px;
}

.register-note {
  font-size: 1.1em;
  font-weight: $regular-font-weight;
  color: $teal;
  margin-bottom: 5px;

  @include mobile-only {
    color: $light-gray;
    font-size: $p-size;
  }
}

.register-note:last-of-type {
  margin-bottom: 15px;
}

.register-complete-button {
  @include mobile-only {
    padding-bottom: 20px;
    justify-content: space-between;
    margin: 0;
    width: calc(100% - 32px);
    position: absolute;
    bottom: 0;
  }
  & > button {
    @include tablet-and-mobile {
      width: 100%;
      padding: 10px;
    }
  }
}