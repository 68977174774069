@import "../../../../Styles/variables";
@import "../../../../Styles/mixins";

.note-mode-chart {
  .holder {
    height: 320px;
    align-items: center;
    background-color: $body_blue;
    color: $light-teal;
    display: flex;
    justify-content: center;
  }

  .divider {
    border-bottom: 1px solid rgba($white, 0.4);
    background-color: #033052;
  }

  .note-mode-chart-footer {
    background-color: $blk-blue;
  }
}
