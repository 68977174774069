@import "../../Styles/variables";
@import "../../Styles/mixins";

.device-reports-container {
  width: 90%;
  margin: 4rem auto;

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include tablet-and-mobile() {
      flex-direction: column;
      align-items: center;

      .header-row-title {
        margin-bottom: 1rem;
        text-align: center;
      }

      button {
        width: 100%;
      }
    }
  }

  .no-results {
    text-align: center;
    width: 100%;
    margin-top: 10rem;
    font-size: 2rem;
  }

  .device-reports-table {
    width: 100%;
    overflow-x: auto;

    @include mobile-only {
      table {
        font-size: 0.8rem;

        th,
        td {
          padding: 0.5rem;
        }
      }
    }

    @include small-mobile-only {
      table {
        font-size: 0.7rem;
      }
    }
  }

  .device-reports-row {
    height: 5rem;
    padding: 3rem 0;
    cursor: pointer;

    &:hover {
      background: $royal-blue;
    }
  }

  .template-name-column {
    text-align: end;
    padding-right: 4rem;

    @include tablet-and-mobile {
      text-align: left;
      padding-right: 1rem;
    }
  }
}

@include small-mobile-only {
  .device-reports-container {
    width: 100%;
    margin: 2rem auto;

    .header-row-title {
      font-size: 1rem;
    }

    button {
      font-size: 0.9rem;
      padding: 0.5rem;
    }
  }
}
