.left-panel {
  top: em($modal-header-height) !important;
}

.right-panel.main {
  margin-left: em($left-rail-width);

  @include tablet-and-mobile {
    margin-left: 0;
  }
}
