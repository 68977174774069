@import '~font-awesome/scss/font-awesome';
@import "variables";

.icon-sprite {
  @include sprites;
  background-size: em(135);

  &.u-text-teal {
    filter: invert(50%) sepia(98%) saturate(990%) hue-rotate(126deg) brightness(100%) contrast(101%)
  }
}

.icon-alert-badge {
  width: 1em;
  height: 1em;
  background-position: 0 0;
}

.icon-sprite-battery-unknown, .icon-sprite-signal-unknown {
  opacity: 0.5;
}

.icon-sprite-signal_100 {
  width: 1em;
  height: 1em;
  background-position: em(-4) em(-5);
}

.icon-sprite-signal_80 {
  width: 1em;
  height: 1em;
  background-position: em(-26) em(-5);
}

.icon-sprite-signal_60 {
  width: 1em;
  height: 1em;
  background-position: em(-48) em(-5);
}

.icon-sprite-signal_40 {
  width: 1em;
  height: 1em;
  background-position: em(-70) em(-5);
}

.icon-sprite-signal_20 {
  width: 1em;
  height: 1em;
  background-position: em(-92) em(-5);
}

.icon-sprite-signal_0 {
  width: 1em;
  height: 1em;
  background-position: em(-114) em(-5);
}

.icon-sprite-battery_100 {
  width: 1.3em;
  height: 1em;
  background-position: em(-4) em(-25);
}

.icon-sprite-battery_75 {
  width: 1.3em;
  height: 1em;
  background-position: em(-30) em(-25);
}

.icon-sprite-battery_50 {
  width: 1.3em;
  height: 1em;
  background-position: em(-56) em(-25);
}

.icon-sprite-battery_25 {
  width: 1.3em;
  height: 1em;
  background-position: em(-82) em(-25);
}

.icon-sprite-battery_0 {
  width: 1.3em;
  height: 1em;
  background-position: em(-108) em(-25);
}

.icon-sprite-new {
  width: 2.9em;
  height: 1.9em;
  background-position: em(-74) em(-42);
}

.icon-sprite-warning {
  width: 2em;
  height: 1.6em;
  background-position: em(-4) em(-43);
}

.icon-sprite-alert {
  width: 2em;
  height: 1.8em;
  background-position: em(-39) em(-42);
}

@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/icomoon.eot?ahhwcw');
  src: url('../assets/fonts/icomoon.eot?ahhwcw#iefix') format('embedded-opentype'),
  url('../assets/fonts/icomoon.ttf?ahhwcw') format('truetype'),
  url('../assets/fonts/icomoon.woff?ahhwcw') format('woff'),
  url('../assets/fonts/icomoon.svg?ahhwcw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon.icon-barometric_pressure {
  @extend .icon-sprite;
  filter: invert(3%) sepia(73%) saturate(561%) hue-rotate(90deg) brightness(95%) contrast(91%);
  background-position: em(-3) em(-75);
  width: 1.6em;
  height: 1.6em;
}

.icon-override:before {
  // content: "\e910";
  content: "\e905";
}

.icon-alarm_no:before {
  content: "\e901";
}

.icon-alarm:before {
  content: "\e902";
}

.icon-analog_digital:before {
  content: "\e903";
}

.icon-binary_input:before {
  content: "\e905";
}

.icon-co:before {
  content: "\e906";
}

.icon-co2:before {
  content: "\e907";
}

.icon-current:before {
  content: "\e90b";
}

.icon-food_temperature:before {
  content: "\e90c";
}

.icon-humidity:before {
  content: "\e90d";
}

.icon-light_level:before {
  content: "\e90e";
}

.icon-no2:before {
  content: "\e90f";
}

.icon-set_point:before {
  content: "\e900";
}

.icon-temperature:before {
  content: "\e911";
}

.icon-voc:before {
  content: "\e912";
}

.icon-battery_volt:before {
  content: "\e913";
}

.icon-temperature_2:before {
  content: "\e914";
}

.icon-temperature_3:before {
  content: "\e915";
}

.icon-temperature_4:before {
  content: "\e916";
}

.icon-temperature_1:before {
  content: "\e918";
}

.icon-replace:before {
  content: "\e919";
}

.fa {
  @extend .fa-fw;
}

.custom-icon {
  cursor: pointer;
}

.fa-rotate {
  transition: 0.2s ease-in;
}

i.rotated {
  transform: rotate(180deg);
}