@import "../../../../Styles/variables";
@import "../../../../Styles/mixins";

.device-detail-modal {
  .add-alert-button {
    align-items: center;
    display: flex;
    gap: 4px;
  }

  @include mobile-only {
    .add-alert-button {
      .fa-plus-circle {
        font-size: 16px;
        margin-bottom: 1px;
      }
    }

    .alert-section .MuiList-root {
      padding-top: 0;
    }
  }

  .holder {
    height: 320px;
    align-items: center;
    background-color: $body-blue;
    color: $light-teal;
    display: flex;
    justify-content: center;
  }

  .alert-header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    > p {
      margin: 0;
    }
  }

  .modal-body {
    padding: 0;

    @include desktop-only {
      max-height: 80vh;
    }
  }

  @include mobile-only {
    .serial-number {
      color: rgba($white, 0.8);
      font-size: 0.5em;
      white-space: break-spaces;
      width: 80px;
    }
  }

  .reading {
    display: flex;
    flex-direction: column;
    height: em(160);
    margin-bottom: 6px;
    padding: 12px 8px;

    @include mobile-only {
      align-items: center;
      flex-direction: row-reverse;
      height: unset;
      min-height: unset;
      justify-content: space-between;
    }
  }

  .reading-title {
    color: $baby-blue;
    font-weight: $regular-font-weight;
    text-transform: uppercase;
    font-size: $small-size;
  }

  .reading-time {
    font-size: $small-size;
    font-weight: $regular-font-weight;
    margin-bottom: 15px;

    @include mobile-only {
      color: $white;
      margin: 0;
    }
  }

  .reading-value {
    font-size: em(50);
    text-align: right;

    .sub {
      font-size: 0.5em;
      vertical-align: text-top;
    }

    @include mobile-only {
      color: $white;
      font-size: $h2-size;
      margin: 0;
    }
  }

  .reading-last {
    font-size: $small-size;
    margin-bottom: 0;
  }

  @include mobile-only {
    .modal-body {
      padding-bottom: 80px;
    }

    .modal-footer {
      background: $body_blue;
      bottom: 0;
      position: absolute;
    }
  }

  .add-to-watchlist-column {
    margin-top: 2.1rem;
  }

  .unit-select {
    min-width: 100px;
  }
}

.mobile-unit {
  align-items: center;
  background-color: $body_blue;
  color: $light-teal;
  font-size: 2em;

  .MuiSelect-select {
    &.MuiSelect-outlined {
      &.MuiInputBase-input {
        &.MuiOutlinedInput-input {
          margin: 8px 4px !important;
          padding: 0 !important;
        }
      }
    }
  }
}

.reading-row {
  @include mobile-only {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.units-hide-row {
  @include mobile-only {
    margin-top: 1rem;
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.graph-break-note {
  margin-bottom: 1em;
  margin-top: 0.75em;
  text-align: center;
}
