.info {
  font-size: $small-size;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1em;

  p {
    margin-bottom: 5px;
  }
}

.info-alert {
  max-width: 65%;
}

.alert-value {
  font-size: 1.5em;
}
