@import "../Styles/mixins";

.pagination-wrapper {
  display: flex;
  margin: 12px auto 24px;

  button.pagination-button {
    text-align: center;
    width: 100px;

    @include mobile-only {
      border-radius: 50%;
      min-width: unset;
      height: 3em;
      width: 3em;
    }
  }

  nav {
    align-content: center;
  }
}


.fa.fa-angle-left,
.fa.fa-angle-right {
  &.angle-icon-pagination {
    font-size: 18px;
    font-weight: bold;
    align-self: center;
  }
}