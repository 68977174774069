@import "../../../Styles/variables";
@import "../../../Styles/_mixins";

.table > thead > tr > th {
  border-bottom: 0 !important;
}

.table > tbody > tr > td {
  border-top: 0 !important;
}

.modal {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: auto !important;
}

.modal::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.bottom-line {
  border-bottom: 1px solid rgba($white, 0.4);
  margin: 12px 0;
}

div.modal-dialog {
  &.device-properties-modal-dialog {
    width: 800px;

    div.device-properties-modal-footer {
      background-color: #033052;
      margin-top: 20px;
      padding: 15px;
    }
  }
}

#device-properties-image {
  background-color: $white;
  margin-bottom: 20px;
  width: 100%;
}

@include mobile-only {
  #device-properties-image {
    width: 50%;
  }
  div.modal-dialog {
    &.device-properties-modal-dialog {
      width: unset;
      margin-left: 10px;
      margin-right: 10px;

      div.device-properties-modal-body {
        padding: 15px;

        div.device-properties-modal-body-main-content {
          display: flex;
          flex-flow: column;

          > div.first-col {
            display: flex;
            flex-direction: column;

            #device-properties-image-container {
              order: -1;
            }
          }

          > div {
            width: 100%;

            div.device-properties-dropdowns-container {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: space-between;

              div.device-properties-dropdown {
                display: flex;
                flex-direction: column;
                flex-basis: 47%;
                align-items: normal;

                .device-properties-dropdown-input {
                  background-color: $marine-blue;
                  border-bottom: unset;
                  align-items: center;

                  > div[role="combobox"] {
                    padding-top: 6px;
                    padding-bottom: 6px;
                  }

                  svg.MuiSvgIcon-root {
                    color: #04cca0;
                    transform: scale(1.5, 1.5);
                  }
                }
              }
            }

            div.input-range {
              flex-wrap: wrap;
              justify-content: space-between;

              > span.MuiSlider-root {
                flex-basis: 100%;
                order: -1;
              }

              > div.input-range-field.input-holder {
                display: flex;
                flex-direction: row-reverse;
                margin-right: 0;
                margin-left: 0;
                width: min-content;

                > input.input {
                  background-color: $marine-blue;
                  min-width: 45px;
                  margin-left: 0;
                }

                > label.input-label {
                  align-self: center;
                  margin-right: 8px;
                  padding-left: 0;
                }
              }
            }
          }
        }

        div#device-properties-bonds-container {
          width: 100%;

          > div.row:not(:first-of-type) {
            margin-top: 10px;
          }

          > div.row {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;

            > div {
              width: auto;
            }

            > div:first-of-type {
              margin-left: auto;
              width: unset;

              img.custom-icon {
                height: 16px;
                width: 14px;
              }
            }

            > div:last-of-type {
              margin-right: auto;
              display: flex;
              align-items: center;

              p {
                vertical-align: middle;
                margin-bottom: 0;
              }
            }
          }
        }

        > div {
          width: unset;
        }
      }

      div.device-properties-modal-footer {
        background-color: unset;
        margin-top: 20px;
        padding: 15px;
        border-top: unset;
      }
    }
  }
}
