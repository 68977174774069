.MuiSelect-select {
  &.MuiSelect-outlined {
    &.MuiInputBase-input {
      &.MuiOutlinedInput-input {
        &.Mui-disabled {
          -webkit-text-fill-color: rgb(185 185 185 / 88%);
          cursor: not-allowed;
        }
      }
    }
  }
}
