.u-full-width {
  width: 100%;
}

.u-flex-start {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.u-flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.u-flex-strech {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.u-desktop-only {
  @include mobile-only {
    display: none !important;
  }
;
  @include tablets-only {
    display: none !important;
  }
;
}

.u-desktop-hide {
  @include desktop-only {
    display: none !important;
  }
}

.u-mobile-hide {
  @include mobile-only {
    display: none !important;
  }
}

.u-mobile-only {
  @include desktop-only {
    display: none !important;
  }

  @include tablets-only {
    display: none !important;
  }
}

.u-tablet-only {
  @include desktop-only {
    display: none !important;
  }

  @include mobile-only {
    display: none !important;
  }
}

.u-tablet-hide {
    @include tablets-only {
        display: none !important;
    }
}

.u-invisible {
  visibility: hidden;
}

.u-display-none {
  display: none !important;
}

.u-display-block {
  display: block;
}

.u-inline-block {
  display: inline-block;
}
