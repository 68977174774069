@import "../Styles/mixins.scss";

.input {
  padding: $padding-base-vertical $padding-base-horizontal;
  letter-spacing: 1px;
  opacity: 1;
  transition: opacity 0.3s $ease-out;

  &.m-slide {
    width: em(150) !important;
    transition: 0.3s width $ease-out;

    &:focus {
      width: em(300) !important;
      transition: 0.3s width $ease-out;
    }
  }

  &::placeholder {
    color: rgba($white, 0.4);
  }

  &:disabled,
  &.disabled {
    opacity: 0.4;
    transition: opacity 0.3s $ease-out;
    pointer-events: none;
  }

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
}

.input-sm {
  min-width: em(60);
  font-size: $small-size;
  padding: 4px 6px;
}

.input-default {
  background: $input-bg;
  border-radius: $input-border-radius;
  border: 1px solid $input-border;

  &:focus,
  &.focus {
    background: rgba($input-bg, 0.8);
    border: 1px solid $teal;
    outline: none;
  }

  &.error {
    border-color: $warning;
  }
}

.input-filter-holder {
  margin: auto 20px;
  min-width: 120px;

  &.narrow-filter {
    min-width: 60px;
  }
}

.input-holder {
  .form-control {
    padding: 0;
    height: unset;
  }
}

.input-holder {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  .input {
    width: 100%;
  }

  .input-holder-icon,
  &:before {
    position: absolute;
    right: 8px;
    @include vertical-center;
  }

  &.mod-block {
    display: block;
  }

  &.mod-line {
    .input-holder-icon,
    &:before {
      right: 1px;
    }
  }

  &.validated:before,
  &.checked:before {
    @extend .fa;
    @extend .fa-check;
    color: $teal;
    font-size: $h5-size;
  }

  &.search:before {
    @extend .fa;
    @extend .fa-search;
    font-size: $h5-size;
  }

  &.select:before {
    @extend .fa;
    @extend .fa-angle-down;
    font-size: $h5-size;
    pointer-events: none;
  }

  &.plusMinus {
    &:before,
    &:after {
      display: block;
      position: absolute;
      left: -15px;
      color: $baby-blue;
      pointer-events: none;
    }

    &:before {
      content: "+";
      top: 45%;
    }

    &:after {
      content: "_";
      bottom: 0;
      font-size: 1.1em;
    }

    .input {
      width: 7.5em;
    }
  }

  &.datepicker {
    @include mobile-only {
      display: flex;

      .input {
        flex: 1;
      }
    }

    .input {
      padding-left: em(54);
      padding-right: $padding-base-horizontal;

      &:focus,
      &.focus {
        & + .input-holder-icon {
          background-color: $teal;
          color: black;
        }
      }

      &:disabled,
      &.disabled {
        & + .input-holder-icon {
          opacity: 0.4;
        }
      }
    }

    .input-holder-icon {
      display: block;
      height: 100%;
      width: 36px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: $royal-blue;
      border-top-left-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
      transform: translate(0, 0);

      &:before {
        @extend .fa;
        @extend .fa-calendar;
        right: auto;
        left: $padding-base-horizontal;
        z-index: 1;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
}

.input-label {
  color: $baby-blue;
  letter-spacing: 1px;
  font-weight: $bold-font-weight;
  font-size: $small-size;
  text-transform: uppercase;

  @include mobile-only {
    font-size: 14px;
    font-weight: 700;
  }

  &.input-label-white {
    color: $white;
  }
}

.input-line {
  background: transparent;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid rgba($white, 0.4);
  padding: $padding-base-vertical 4px;

  &:focus,
  &.focus {
    outline: none;
    border-bottom: 1px solid $white;
  }

  .error > &,
  &.error,
  .input-error > & {
    border-bottom: 1px solid #ff0000;
  }
}

.input-error-message {
  color: rgba($white, 0.8);
  @extend .type-small-regular;
}

.input-select {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;

  &:focus,
  &.focus {
    outline: 1px solid $teal;
  }
}

.input-textarea {
  background: $marine-blue;
  width: 100%;
  border: 0;

  &:focus,
  &.focus {
    outline: 1px solid $teal;
    border: 1px solid $teal;
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
  }
}

.checkbox-input-error,
.switch-input-error,
.toggle-input-error {
  color: $alert;
  font-size: $font-size-small;
  position: absolute;
  text-transform: none;
}

.form-group {
  position: relative;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;

  &:hover {
    visibility: visible;
  }

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: none !important;
  -webkit-background-clip: text !important;
}

input {
  -webkit-text-fill-color: $white !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

.select-input-subtitle-label {
  color: $baby-blue !important;
  opacity: 1 !important;
  font-size: $font-size-small;
  font-weight: $bold-font-weight;
  text-transform: uppercase;
}

.checkbox-teal {
  .input-label-checkbox:before,
  .input-checkbox:checked + .input-label-checkbox:not(.isSaving)::before {
    height: 16px;
    width: 16px;
    font-size: 16px;
  }

  .input-checkbox:checked + .input-label-checkbox:not(.isSaving)::before {
    color: $teal;
  }

  .input-checkbox:disabled + .input-label-checkbox:not(.isSaving)::before {
    cursor: not-allowed;
    opacity: 0.75;
    background: #2c4960;
  }
}
