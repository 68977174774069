@import '../Styles/variables';
@import '../Styles/mixins';

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: $marine-blue;
  border-bottom: rgba(#00070c, 0.4);
  height: em($header-row-height);
  margin-bottom: 2em;

  @include desktop-only {
    position: fixed;
    z-index: 1000;
  }

  .offline {
    opacity: 0.5;
  }
  &.extended {
    height: unset;
    flex-direction: column;
  }

  .subtext {
    margin-top: -10px;
    font-size: 11px;
    float: left;
    position: absolute;
  }

  @include tablet-and-mobile {
    flex-wrap: wrap;
    row-gap: 1em;
  }
}

.header-row-title {
  align-items: center;
  font-size: em(16);
  font-weight: $semi-bold-font-weight;
  letter-spacing: 1px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 0 0.8em 0 0;

  @include no-wrap;

  &.mod-sub {
    font-size: $small-size;
    color: $baby-blue;
    text-transform: uppercase;
    margin-right: 2em;
  }
}
.chip-data-css-container {
  &.full {
    .chip-container{
      max-height: unset;
    }
  }
}
.header-row-section {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 6px $grid-gutter-default;

  @include tablets-only {
    height: unset;
  }

  &.mod-sub {
    padding: 0;
  }

  &.mod-column {
    flex-direction: column;
  }

  &.chip-container {
    justify-content: unset;
    flex-wrap: wrap;
    overflow: hidden;


    gap: 10px 8px;
    padding-right: 80px;
    max-height: 45px;
    &.full {
      max-height: unset;
    }
  }

  &.last {
    border-left: 1px solid $blk-blue;
    padding-right: $grid-gutter-default;
    justify-content: center;
  }

  &:first-child {
    padding-left: $grid-gutter-default * 2;
    @include tablets-only {
      padding: 6px $grid-gutter-default;
    }
  }

  &.full-width {
    width: 100%;
    position: relative;
  }

  &.highlight {
    background-color: rgba($royal-blue, 0.6);
    height: 100%;
  }

  &.large {
    min-width: 210px;
    width: 300px;
  }
}

.header-row-info {
  display: flex;
  align-items: center;
}

.header-row-info-control {
  margin-left: 1em;
  display: flex;
  align-items: center;

  @include tablet-and-mobile {
    margin-left: auto;
    margin-right: 0;
  }
}

.header-row {
  @include mobile-only {
    font-size: 0.8em;

    &-section:first-child {
      padding-left: $grid-gutter-default;
      padding-bottom: 6px;
    }
  }
}