@import '../variables';

.bapi-header {
  background-image: linear-gradient(to bottom, #0068b3, #005694);
  height: 64px;
  padding: 12px $grid-gutter-width * 0.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 1049;
  top: 0;
  position: fixed;

  @include mobile-only {
    height: 48px;
    padding: 12px $grid-gutter-width * 0.25;

    > * {
      text-align: center;
    }
  }
}

.bapi-logo {
  height: 100%;
  display: block;

  &-holder {
    height: 100%;

    @include tablet-and-mobile {
      text-align: center;
    }
  }

  &-image {
    height: 100%;
    width: auto;
  }
}

.bapi-header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;

  @include tablet-and-mobile {
    justify-content: flex-end;
  }

  &-item {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    list-style-type: none;
  }

  & > ul {
    padding-left: 0;
    margin: 0;
  }

  &-link, &-toggle {
    font-size: 16px;
    font-weight: $bold-font-weight;
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 1px;
  }

  &-toggle {
    color: $white;
  }

  &-link {
    color: $baby-blue;
    margin-right: 2em;
    position: relative;

    &:hover {
      color: $white;
    }

    &.active {
      background: transparent;
      color: $white;

      &:after {
        transform: scale(1);
        transition: transform 0.3s $ease-out;
      }
    }

    &:after {
      width: 100%;
      height: 1px;
      background-color: $white;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      transform: scale(0);
      transform-origin: left;
      transition: transform 0.3s $ease-out;
    }
  }
}

.left-rail-drawer {
  .drawer-item {
    font-size: 16px;
    font-weight: $semi-bold-font-weight;
  }
}

.left-rail-toggle-wrapper,
.left-rail-drawer {
  @include desktop-only {
    display: none;
  }
}

.left-rail-toggler {
  margin-top: -4px;
  @include desktop-only {
    display: none;
  }
}

.mobile-header {
  flex-direction: column;
}

.bapi-header-nav-toggles {
  white-space: nowrap;

  .bapi-header-nav-toggle.btn.btn-icon {
    padding: 2px;
  }
}

