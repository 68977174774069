@import '../../Styles/variables';
@import '../../Styles/mixins';

.terms {
  @include mobile-only{
    margin-bottom: 20px;
  }
}


.account-error {
  color: $alert;
  margin-top: 8px;

  @include mobile-only {
    margin: -20px 0 20px 0;
  }
}