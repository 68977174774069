@import '../../Styles/variables';
@import '../../Styles/mixins';

.mobile-header-row {
    display: grid !important;
    grid-template-columns: 1fr;
    gap: 16px;

    .mobile-search-input-holder {
        width: 100%;
    }
}

.alert-name-cell {
    width: 33%;
    padding: 0 12px;
    @include mobile-only {
        width: 100%;
    }
}