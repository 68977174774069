.u-color-teal {
  color: $teal;
}

.u-color-light-teal {
  color: $light-teal;
}

.u-color-light-blue {
  color: $baby-blue;
}

.u-color-white {
  color: $white;
}

.u-color-warning {
  color: $warning;
}

.u-color-alert {
  color: $alert;
}

.u-opacity-fade {
  opacity: 0.8;
}
