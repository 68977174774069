@import "../../Styles/variables";
@import "../../Styles/mixins";

.translations {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 4rem);

  .translations-table-wrapper {
    height: 100%;
    margin: 24px;
    padding-top: em($header-row-height);
    overflow: hidden;

    @include tablet-and-mobile {
      margin-top: 0;
      padding-top: em($header-row-height / 2);
    }
  }

  .translations-table {
    flex: 1 1;
    height: 100%;
    overflow: auto;

    thead {
      position: sticky;
      top: 0;
    }
  }

  .header-row .btn {
    @include desktop-only {
      margin-right: 12px;
      
      &:last-of-type {
        margin-right: 30px;
      }
    }
  }
}