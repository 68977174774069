@import "../../Styles/variables";
@import "../../Styles/mixins";

.mobile-alert-condition {
  @include mobile-only {
    display: grid;
    gap: 10px;
  }

  .mobile-alert-condition-row {
    @include tablet-and-mobile {
      color: $white;
      font-size: 1.5rem;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
    }

    .mobile-alert-condition-value {
      @include mobile-only {
        margin: auto 0;
      }

      @include tablets-only {
        font-size: 1.25rem;
        font-weight: 300;
        padding-top: 0.5rem;
      }
    }
  }
}

.mobile-alert-types-row {
  @include tablet-and-mobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @include mobile-only {
    margin-left: 5px;
  }

  @include tablets-only {
    padding-top: 2rem;
  }
}

.alerts-label-mobile-tablet {
  @include mobile-only {
    margin-bottom: 3px;
  }
}

.alert-mobile-info-header {
  @include mobile-only {
    margin-top: -2rem;
  }
}

.explanation {
  @include mobile-only {
    margin-bottom: 2px;
  }
}

.mobile-protocol {
  @include mobile-only {
    margin-bottom: unset;
  }
}

.alert-recipients-mobile-tablet {
  margin-bottom: 20px;

  .select-group-item {
    &.header {
      display: none;
    }
  }

  .alert-recipient-row {
    display: grid;
    grid-template-columns: 3fr 3fr 1fr;
    grid-gap: 5px 10px;
    padding: 10px;

    @include tablets-only {
      grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
      padding-bottom: 5px;
    }

    .alert-field {
      margin-bottom: 10px;
      padding: unset;
      display: flex;
      flex-direction: column;
    }

    .delete-alert {
      justify-self: end;
      margin-bottom: unset;
      margin-top: 10px;

      button {
        padding: unset;
        margin: 0 !important;
        margin-left: auto !important;
      }
    }

    .alert-label {
      display: block;
      font-weight: bold;
      padding: unset;
    }

    &:nth-child(6) {
      grid-column: span 2;
    }
  }

  .remove-alert-recipient-icon {
    scale: 1.25;
    margin-right: 5px;
    position: relative;
    top: -1px;

    @include tablet-and-mobile {
      scale: 1.5;
      margin-left: auto;
    }
  }
}

.alert-list-category-name {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #80c2f3;
  text-transform: uppercase;
  padding-left: 1.5em;
  padding-top: 1.5rem;

  &.category-name-configured {
    padding-top: 0.5rem;
  }
}

.main-panel-alerts {
  @include tablet-and-mobile {
    background: $dark-blue;
  }
}

.bapi-alerts-dashboard,
.bapi-no-alerts-dashboard {
  @include tablet-and-mobile {
    .header-row {
      margin-bottom: unset;
    }
  }
}

.no-alerts-panel {
  @include tablet-and-mobile {
    height: 100%;

    p {
      margin-top: 30%;
    }
  }
}

.header-row-alerts-mobile-only {
  @include mobile-only {
    padding: 16px 16px 16px 0;
  }
}

@include mobile-only {
  .alerts-mobile-first-section {
    background-color: $dark-blue;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 16px;

    .alert-name {
      font-weight: 700;
      font-size: 1.5rem;
      padding-left: 3px;
    }

    .devices-usage {
      color: #b2bac0;
    }
  }
}

.mobile-tablet-icon {
  @include tablet-and-mobile {
    scale: 1.1;
  }
}

@include tablets-only {
  .mobile-button {
    font-size: 1.5rem !important;
  }
}

@include tablets-only {
  .alerts-left-rail-icon-wrapper {
    div {
      margin-right: 2.5rem;
      position: relative;
      top: 3px;
    }
  }
}

@include tablets-only {
  .tablet-drawer {
    .MuiPaper-root {
      overflow-x: hidden;
    }
  }
}

.default-alerts-list {
  margin-bottom: 0 !important;
}
