@import '../../Styles/variables';
@import '../../Styles/mixins';
@import '../../Styles/variables';

.alert-config-modal {
  display: flex;
  flex-direction: column;
}

.alert-config-modal-row:not(.mobile-protocol) {
  display: flex;
  gap: 16px;
  padding-bottom: 1rem;

  @include mobile-only {
    flex-direction: column;
    .input-wrapper {
      display: flex;

      .unit {
        margin: auto;
      }
    }
  }

  > * {
    padding: 0;
  }
}

.alert-row.row {
  margin: 0;

  > div {
    padding: 0;
  }
}

.alert-config-modal-flex-group {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  .input-range {
    width: calc(100% - 7.5rem);
  }

  @include mobile-only {
    .input-range {
      width: 95%;
      margin: auto;
    }
  }

  .input-holder .input {
    @include mobile-only {
      min-width: 4rem;
    }
  }
}

.input-holder > input.input-threshold {
  background-color: #042b4a;
  padding-left: 6px;
  margin-right: 8px;
  width: 7.5em;
}


@include mobile-only {
  .form-group {
    width: 100%;
  }
}

@include mobile-only {
  .mobile-unit-threshold-row {
    display: flex;
    gap: 4rem;
    margin: 15px 0;
  }
}

.plus-minus-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1ch;
  position: relative;
  top: 3px;
}

.plus-minus-container span {
  line-height: 1;
}

.threshold-wrapper {
  display: flex;
  gap: 5px;
}

.minute-related-values {
  .value {
    @include mobile-only {
      align-items: flex-end;
      display: flex;
      gap: 16px;
      padding-bottom: 16px;

      > * {
        margin-bottom: 0;
      }
    }
  }

  .explanation {
    @include mobile-only {
      color: $light-gray;
    }
  }
}