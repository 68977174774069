.container-full {
  width: 100%;
  padding-left: $grid-gutter-default;
  padding-right: $grid-gutter-default;
}

.main-panel {
  flex: 1;
  padding-left: $grid-gutter-default * 2;
  padding-right: $grid-gutter-default * 2;
  padding-bottom: $grid-gutter-default * 2;
  width: 100%;

  @include desktop-only {
    padding-top: em($header-row-height) + 2em;

    &.extended {
      padding-top: em($header-row-height * 2) + 2em;
    }
  }

  &.inactive {
    opacity: 0.4;
  }

  &.isLoading {
    &:after {
      width: 100%;
      height: 100%;
      background-color: rgba($blk-blue, 0.4);
      content: '';
      position: absolute;
      top: 65px;
      left: 19.92857em;
      z-index: 100;
    }

    &:before {
      @extend .fa;
      @extend .fa-circle-o-notch;
      @extend .fa-spin;
      position: absolute;
      top: 25vh;
      left: calc(50% + 5em);
      z-index: 101;
      font-size: 2em;
      opacity: 0.8;
    }
  }

  @include mobile-only {
    padding-left: $grid-gutter-default;
    padding-right: $grid-gutter-default;
    &.isLoading:after {
      left: 0;
      top: 52px;
    }

    &.isLoading:before {
      left: 50%;
    }
  }
}

.main-panel-row {
  margin-bottom: 15px;
  // padding-left: $grid-gutter-default;
  // padding-right: $grid-gutter-default;

  &.full-width {
    width: 100%;
  }
}

