.nav-tabs {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;

  .nav-item {
    list-style: none;
    min-width: 20%;

    &:last-child {
      position: relative;

      &:after {
        content: '';
        background-color: rgba(0, 27, 46, 0.25);
        width: 999px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 100%;
      }
    }
  }

  .nav-link {
    padding: $padding-large-vertical $padding-large-horizontal;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 0 0.5rem;
    color: $light-teal;
    text-align: center;
    font-size: em(14);
    text-transform: uppercase;

    background: rgba(0, 27, 46, 0.25);
    border-right: 1px solid $card-blue;

    &:not(.active).hover,
    &:not(.active):hover,
    &:not(.active):focus,
    &:not(.active).focus {
      background-color: rgba(128, 194, 255, 0.6);
      color: $card-blue;
    }

    &.active {
      background-color: transparent;
    }
  }

  .icon-barometric_pressure {
    font-size: 0.7em;
    vertical-align: middle;
  }
}

.tab-pane {
  display: none;
  padding: $grid-gutter-default * 2 $grid-gutter-default;

  @include mobile-only {
    padding: $grid-gutter-default * 1.5 $grid-gutter-default
  }

  &.active {
    display: block;
  }
}
