@import '../Styles/variables';
@import '../Styles/mixins';

.MuiDrawer-paper {
  height: calc(100% - 64px) !important;
  margin-top: 64px;
  width: em($left-rail-width);

  @include mobile-only {
    height: calc(100% - 48px) !important;
    margin-top: 48px;
  }
}

.left-rail {
  width: em($left-rail-width);
  background-color: $dark-blue;
  letter-spacing: 1px;
  position: fixed;
  left: 0;
  top: 64px;
  bottom: 0;
  height: 100%;
  padding-bottom: 64px;

  @include tablet-and-mobile {
    width: inherit;
    position: unset;
    padding-bottom: 9px;
    margin-bottom: 16px;
  }

  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    background-color: $marine-blue;
    position: absolute;
    width: 10px;
    margin-right: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(128, 194, 255, 0.4);
    border-radius: 6px;
    width: 8px !important;

    @include link-active {
      background-color: rgba(128, 194, 255, 0.5);
    }
  }

  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }
}

.left-rail-header {
  padding: 6px $grid-gutter-default 0 $grid-gutter-default;

  @include mobile-only {
    font-size: 0.8em;
  }
}

.left-rail-body {
  padding: 6px $grid-gutter-default;
}

.left-rail-header {
  min-height: em($header-row-height);
  background: $dark-blue;
  border-bottom: 1px solid #000000;
  height: auto;

  display: flex;
  align-items: center;

  & > .dropdown {
    width: 100%;
  }
}

.left-rail-title {
  color: $white;
  font-size: $h5-size;
  font-weight: $bold-font-weight;
  margin: 0 0 em(5) 0;

  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  justify-content: flex-start;
  gap: 0 0.5rem;

  a {
    color: $white;
  }
}

.left-rail-info {
  font-size: $small-size;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-rail-nav {
  .left-rail-nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: em(9) 0;
    border-bottom: 1px solid $teal;

    &-title {
      color: $teal;
      text-transform: uppercase;
      font-size: $small-size;
      margin: 0;

      &.mod-with-btn {
        padding-left: 0;

        .btn {
          text-align: left;
          width: em(30);
        }
      }
    }

    .btn-circle {
      font-size: 0.7em;
    }
  }

  &-group {
    padding: 0;
    margin: em(10) -15px em(36) 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-item {
    list-style: none;
    padding-left: 1.5em;

    &-sub {
      font-size: $small-size;
      @include no-wrap;
    }

    &.last {
      margin-top: em(15);
    }

    &.active {
      background-color: $body-blue;
      box-shadow: inset 8px 0 0 0 $royal-blue;
    }

    & > * {
      color: $white;
      padding: 8px 0;
      //display: block;
      cursor: pointer;

      display: flex;
      flex-direction: row;
      align-items: center;
    }

    @include link-active {
      background-color: $body-blue;
    }

    &-main {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 60px);

      &.full {
        width: 100%;
      }
    }

    &-title {
      max-width: calc(100% - 34px);
      @include no-wrap;
      width: auto;
      display: inline-block;
      vertical-align: middle;
    }

    &-number {
      color: $light-teal;
      font-size: 0.8em;

      // Truing up some styles between the systems
      display: inline-block;
      padding: 3px 0 1px 4px;
      vertical-align: bottom;

      &.warning {
        color: $warning;
      }
    }

    &-icon {
      color: $light-teal;
      display: inline-block;
      vertical-align: middle;
      width: 47px;
      font-size: $small-size;
      text-align: right;

      & > .icon-sprite {
        vertical-align: text-top;
      }
    }
  }
}
