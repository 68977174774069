@import "../variables";
@import "../mixins";

.dropdown.show,
.dropdown.open {
  & > .dropdown-menu,
  & > * > .dropdown-menu {
    color: $teal;
    display: block;
    min-width: em(190);
    top: calc(100% + 0.8em);
  }

  .dropdown-menu-right {
    right: -0.3em;
    left: auto;
  }

  .dropdown-menu-left-side {
    left: calc(-100% - 0.7em);
    top: -1em;
    width: 190px;

    &:after {
      border-bottom: 10px solid transparent;
      border-top: 10px solid transparent;
      border-left: 14px solid $dropdown-bg;
      right: -24px;
      top: 1em;
    }
  }
}

.dropdown-trigger {
  cursor: pointer;
  padding: 6px 30px;

  &:hover {
    background-color: $card-blue;
  }
}

.dropdown-toggle.disabled {
  pointer-events: none;
}

.dropdown-menu.dropdown-arrowless:after {
  display: none;
}

.dropdown-menu:after {
  content: "";
  width: 0;
  height: 0;
  border-bottom: 14px solid $dropdown-bg;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  top: -12px;
  right: 0.5em;
}

.dropdown-menu-left-side:after {
  right: unset;
  left: 0.5em;
}

.dropdown-menu-title {
  padding: $padding-base-vertical $grid-gutter-default;
  color: $teal;
  text-transform: uppercase;
  font-size: em(12);
  margin: 0;
}

.dropdown-menu-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 12px;
  padding: $padding-base-vertical $grid-gutter-default * 2;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  width: 100%;
  white-space: nowrap;

  @extend .type-regular;

  &.disabled,
  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.active,
  &:focus,
  &:active,
  &:hover {
    background-color: $card-blue;
  }

  &.non-click {
    color: $light-gray;

    &.active,
    &:focus,
    &:active,
    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }

  &.selected {
    gap: 4px;
    padding: 6px 8px;
  }

  &.separated {
    border-top: 1px solid $dropdown-divider-color;
  }

  &.desktop-hide {
    @include desktop-only {
      display: none;
    }
  }

  &.submenu-trigger {
    position: relative;

    > i {
      position: absolute;
      right: 10px;
    }
  }

  .opened {
    transform: rotate(180deg);
  }
}

.dropdown-menu-item-expandable {
  gap: 0;
}

.dropdown-submenu {
  display: flex;
  flex-direction: column;
  padding: 8px 12px;

  > ul {
    padding: 0;
  }

  > .dropdown-submenu-header {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    padding-bottom: 12px;

    > .dropdown-submenu-header-title {
      color: $white;
      text-align: center;
    }
  }
}

.dropdown-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
}

.dropdown-menu-submenu {
  list-style-type: none;
  padding-left: 0;
  width: 100%;

  .dropdown-menu-submenu-item {
    padding: 6px 0 6px 35px;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    display: flex;
    width: 100%;

    &:hover {
      background-color: $card-blue;
      cursor: pointer;
    }
  }
}
