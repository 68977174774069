@import "../../Styles/_variables";
@import "../../Styles/mixins";

.export-users .header-row-section {
  > div {
    flex: 1;
    height: inherit;
  }

  .header-row-title {
    width: unset;
    @include mobile-only {
      font-size: 16px;
    }
  }

  .header-row-info-control {
    width: 50%;

    .MuiInputBase-root {
      width: 100%;
    }
  }

  .input-label {
    margin: 0;
  }

  .form-control {
    padding: 0;
  }

  .form-group {
    margin: 0;
  }
}
.export-users {
  height: calc(100vh - 64px);
  .export-users-panel {
    height: 0;
    display: flex;
    flex-direction: column;

    > .user-table-wrapper {
      flex: 1;
      overflow: auto;
      width: 100%;

      .MuiTableCell-root {
        white-space: normal;
      }
    }

    > table {
      flex: 1;
    }

    thead {
      background-color: transparent;
      position: sticky;
      top: 0;
      z-index: 1000;
    }
  }
}
@include tablet-and-mobile {
  .export-users {
    @include mobile-only {
      background-color: $dark-blue;
      height: calc(100vh - 48px);
    }

    header.header-row {
      @include mobile-only {
        background-color: $dark-blue;
        margin-bottom: 0;
        padding-bottom: 2em;
      }
      height: auto;
      .header-row-section {
        .header-row-info {
          @include mobile-only {
            flex-direction: column;
          }
          .header-row-title {
            @include mobile-only {
              align-self: baseline;
              margin-bottom: 1em;
              margin-top: 1em;
            }
          }
          .header-row-info-control {
            width: auto;
            display: flex;
            flex-wrap: wrap;
            > div {
              width: auto;
            }

            @include mobile-only {
              gap: 1em;
              font-size: 14px;
              flex-direction: column;
              width: 100%;
              margin-left: 0;
              align-items: baseline;
              > div {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
                svg.MuiSvgIcon-root {
                  color: #04cca0;
                  transform: scale(1.5);
                }
              }
            }
          }
        }
      }
    }

    .export-users-panel {
      @include mobile-only {
        background-color: $dark-blue;
      }
      margin-bottom: 0;
      padding-bottom: 0;
      div.user-table-wrapper {
        height: 100%;
        thead > tr {
          border-bottom: 2px solid $royal-blue;
        }
        table,
        tbody {
          background-color: $marine-blue;
          border-bottom: unset;
        }
        table tbody {
          overflow: auto;
        }
      }

      .main-panel-footer {
        height: 84px;
        align-content: center;
        text-align: end;
        width: 100%;
        button {
          padding: 10px 30px;
        }
        @include mobile-only {
          text-align: center;

          button {
            padding: 10px;
            width: 50%;
          }
        }
      }
    }
  }
}
