@import '../Styles/variables';
@import '../Styles/mixins';

.currency-modal {
  padding: 0 !important;
  .MuiTabPanel-root {
    padding: 30px;

    @include mobile-only {
      padding: 10px;
    }
  }
}


.country-list {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 10px;

  .left-rail-nav-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    &:not(.checked) {
      opacity: 0.6;
    }
    & > * {
      padding: 0;
    }
    label {
      cursor: pointer !important;
    }
  }

  @include mobile-only {
    grid-template-columns: auto auto;

    label {
      font-size: 10px;
      white-space: wrap;
  }
  }
}

