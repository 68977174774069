/* this file will be extracted to main dist folder and is imported in index.html */
/* This file is for setting global styles  */
@import '~font-awesome/scss/font-awesome';

@import 'variables';
@import 'mixins';

// Links to external assets
@import 'bootstrap/imports';
@import 'icons';

// Basic scaffolding
@import 'button';
@import 'checkbox';
@import 'input';
@import 'layout';
@import 'list';
@import 'modal';
@import 'reset';
@import 'typography';

// UI units
@import 'units/imports';

// utilities
@import 'utils/imports';

// component
@import 'components/alert-modal';


// Material UI tries to override this but our design relies on this
body {
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 14px !important;
  line-height: 1.428571429 !important;
}

// TODO: Various uses of '/' in imported CSS rules are triggering this warning, we should refactor:
//<w> Deprecation Using / for division outside of calc() is deprecated and will be removed in Dart Sass 2.0.0.
//<w>
//<w> Recommendation: math.div(30em, 14) or calc(30em / 14)
//<w>
//<w> More info and automated migrator: https://sass-lang.com/d/slash-div
//<w>
//<w> node_modules/font-awesome/scss/_variables.scss 12:24  @import
//<w> node_modules/font-awesome/scss/font-awesome.scss 6:9  @import
//<w> src/Styles/styles.scss 3:9                            root stylesheet
