@import '../Styles/variables';

.file-input {
  input[type=file] {
    opacity: 0;
    position: absolute;
    height: 100%;
    z-index: 10;

    &:hover {
      cursor: pointer;
    }
  }

  .file-input-label {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 200px;
    border: 1px solid $teal;
    border-radius: 10px;
    gap: 8px;

    > div {
      display: flex;
      gap: 4px;
    }

    &.error {
      border-color: $alert;
    }
  }
}